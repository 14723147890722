import React from "react";
import { Icon } from "../../ui";
import { aboutCount, aboutPagePoints } from "../home/mock";

const AboutUs = () => {
  return (
    <>
      <div className="commna_herosec position-relative">
        <div className="container">
          <div className="text-white">
            <h1 className="text-[50px] md:text-[40px] sm:text-[32px] tsm:text-[24px] font-bold ">
              About Us
            </h1>
            <p className="tsm:text-sm">
              Your Trusted Partner in Secure Payments
            </p>
          </div>
        </div>
      </div>
      <div div className="container">
        <section className="comman_space">
          <div className="flex sm:flex-col items-center gap-4">
            <div className="w-[40%] sm:w-full">
              <div className="text-primary-50">
                <span className="border-b border-primary-main">Over 183K+</span>
                <h4 className="text-[40px] md:text-[36px] sm:text-[30px] tsm:text-[26px]">
                  Global User Participation
                </h4>
              </div>
            </div>
            <div className="w-[60%] sm:w-full">
              <div className="grid grid-cols-2 gap-4 tsm:grid-cols-1">
                {aboutCount?.map((item, index) => (
                  <div
                    key={index}
                    className="text-primary-50 w-full comman_shadow text-center p-4"
                  >
                    <h3 className="text-[50px] md:text-[40px] sm:text-[36px] tsm:text-[26px] font-thin">
                      {item?.number}
                      {item.symbol}
                    </h3>
                    <p>{item.text}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
        <section className="comman_space !pt-0">
          <div className="text-center w-[50%] sm:w-[75%] tsm:w-full m-auto">
            <span className="border-b border-primary-main">Who We Are</span>
            <h4 className="text-4xl sm:text-xl tsm:text-lg font-normal">
              Foundation of Quality, Built with Integrity
            </h4>
            <p className="py-4 text-black text-lg tsm:text-sm text-primary-50">
              We specialize in manufacturing high-quality concrete blocks that
              combine durability and sustainability, providing reliable building
              solutions for a wide range of projects.
            </p>
          </div>
          <div className="grid grid-cols-3 sm:grid-cols-2 tsm:grid-cols-1 gap-4 pt-5">
            {aboutPagePoints?.map((item, index) => (
              <div key={index} className="comman_shadow p-6 rounded-lg">
                <Icon name={item?.icon} className="mb-2" />
                <h5 className="text-primary-50 text-2xl md:text-lg tsm:text-lg font-semibold py-2">
                  {item?.phase}
                </h5>
                <p className="text-primary-50 text-base sm:text-sm">
                  {item?.text}
                </p>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;
