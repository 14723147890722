import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../ui";
import Button from "../../ui/elements/Button";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();

  const [formtype, setFormtype] = useState("login");
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRegister = async () => {
    setLoading(true); // Start loading
    try {
      const response = await fetch(
        "https://api.awriabindia.com/api/register-user",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const data = await response.json();
      if (response.ok) {
        toast.success(data?.message);
        setFormData({
          name: "",
          email: "",
          password: "",
        });
        setFormtype("login");
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false); // Stop loading after request completes
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://api.awriabindia.com/api/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
        }),
      });

      // console.log("🚀 ~ handleLogin ~ response:", response.json())
      const data = await response.json();
      if (response.ok) {
        localStorage.setItem("accessToken", data?.access_token);
        toast.success(data?.message);
        // navigate("/home");
        setTimeout(() => {
          navigate("/home");
        }, 2000);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="commna_herosec position-relative">
        <div className="container">
          <div className="text-white">
            <h1 className="text-[50px] md:text-[40px] sm:text-[32px] tsm:text-[24px] font-bold ">
              Log In
            </h1>
          </div>
        </div>
      </div>
      <section className="comman_space">
        <div className="container">
          <div className="w-1/2 m-auto sm:w-[80%] tsm:w-full tsm:p-4 comman_shadow p-8">
            <div className="w-[70%] sm:w-full m-auto text-center">
              <h4 className="text-2xl text-primary-main font-semibold ">
                {formtype === "login" ? "Log In" : "Create Account"}
              </h4>
              <p className="text-primary-100 tsm:text-sm">
                {formtype === "login"
                  ? "Log in to access your account and explore all the features we have to offer."
                  : "Create an account to get started with our services and unlock exclusive benefits."}
              </p>
            </div>

            {formtype === "register" ? (
              <div>
                <div className="form_field">
                  <div className="relative">
                    <label htmlFor="name">Name</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter Name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form_field">
                  <div className="relative">
                    <label htmlFor="email">Email</label>
                    <input
                      autocomplete="off"
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form_field">
                  <label htmlFor="password">Password</label>
                  <div className="relative">
                    <span
                      onClick={togglePasswordVisibility}
                      className="icon_position cursor-pointer"
                    >
                      {showPassword ? (
                        <IoEyeOutline size={18} />
                      ) : (
                        <IoEyeOffOutline size={18} />
                      )}
                    </span>
                    <input
                      autocomplete="off"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="form-control"
                      placeholder="Enter Password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <p className="text-end pb-4 tsm:text-sm">
                  have account ?{" "}
                  <span
                    className="text-primary-main cursor-pointer"
                    onClick={() => {
                      setFormtype("login");
                    }}
                  >
                    Log In
                  </span>{" "}
                </p>
                <Button className="primarybtn w-full " onClick={handleRegister}>
                  {loading ? "Submit..." : "Register"}
                </Button>
              </div>
            ) : null}

            {formtype === "login" ? (
              <div>
                <div className="form_field">
                  <div className="relative">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      name="email"
                      className="form-control"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="form_field">
                  <label htmlFor="password">Password</label>
                  <div className="relative">
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Enter Password"
                      value={formData.password}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <p className="text-end pb-4 tsm:text-sm">
                  Don't have an account?{" "}
                  <span
                    className="text-primary-main cursor-pointer"
                    onClick={() => setFormtype("register")}
                  >
                    Register here
                  </span>
                </p>
                <Button
                  className="primarybtn w-full "
                  onClick={handleLogin}
                  disabled={loading}
                >
                  {loading ? "Logging in..." : "Log In"}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
