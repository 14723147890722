import React, { useEffect, useState } from "react";
import { Icon } from "../../ui";
import Button from "../../ui/elements/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductInquiry = () => {
  const [formData, setFormData] = useState({
    product_name: "",
    customer_name: "",
    address: "",
    phone_number: "",
    email: "",
    note: "",
    accepted_date: "",
  });

  const [userData, setUserData] = useState(null); // Separate state for user data
  console.log("🚀 ~ ProductInquiry ~ userData:", userData);

  const productOptions = [
    "High Alumina Refractory",
    "SK Series",
    "Insulation Bricks",
    "JM Series",
    "Insulation Material",
    "Monolithic Castable",
    "MORTAR",
    "Acid Proof Bricks",
    "Magnesite Bricks",
  ];

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      fetch("https://api.awriabindia.com/api/user", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            setUserData(data); // Set fetched user data
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }
  }, []);

  // Update formData with user data when userData is available
  useEffect(() => {
    if (userData) {
      setFormData((prevData) => ({
        ...prevData,
        customer_name: userData?.data?.name || "",
        email: userData?.data?.email || "",
      }));
    }
  }, [userData]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        "https://api.awriabindia.com/api/product-inquiry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        }
      );
      const data = await response.json();
      if (response.ok) {
        toast.success(data?.message);
        setFormData({
          product_name: "",
          customer_name: "",
          address: "",
          phone_number: "",
          email: "",
          note: "",
          accepted_date: "",
        });
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="commna_herosec position-relative">
        <div className="container">
          <div className="text-white">
            <h1 className="text-[50px] md:text-[40px] sm:text-[32px] tsm:text-[24px] font-bold ">
              Product Inquiry
            </h1>
          </div>
        </div>
      </div>

      <section className="comman_space">
        <div className="container">
          <div className="flex sm:flex-col sm:gap-4 items-center gap-8">
            <div className="w-1/2 sm:w-full">
              <Icon
                name="register"
                className="m-auto w-[450px] sm:w-[350px] tsm:w-[300px]"
              />
            </div>
            <div className="w-1/2 sm:w-full">
              <div className=" comman_shadow p-8 sm:p-6 tsm:p-4">
                <div className="commantext m-auto">
                  <span className="border-b border-primary-main">
                    WORK PROCESS
                  </span>
                  <h4 className="font-extrabold">Product Inquiry Form</h4>
                </div>
                <div className="contact_form">
                  <div className="form_field">
                    <label>Customer Name</label>
                    <input
                      type="text"
                      name="customer_name"
                      className="form-control"
                      placeholder="Enter Customer Name"
                      value={formData.customer_name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form_field">
                    <label>Product Name</label>
                    <select
                      name="product_name"
                      className="form-control"
                      value={formData.product_name}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="" disabled>
                        Select Product
                      </option>
                      {productOptions.map((product, index) => (
                        <option key={index} value={product}>
                          {product}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form_field">
                    <label>Date</label>
                    <input
                      type="date"
                      name="accepted_date"
                      className="form-control"
                      value={formData.accepted_date}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="form_field">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="form_field">
                    <label>Phone Number</label>
                    <input
                      type="tel"
                      name="phone_number"
                      className="form-control"
                      placeholder="Enter Phone Number"
                      value={formData.phone_number}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="form_field">
                    <label>Address</label>
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                      placeholder="Enter Address"
                      value={formData.address}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                  <div className="form_field">
                    <label>Note</label>
                    <textarea
                      name="note"
                      className="form-control"
                      placeholder="Enter Note"
                      value={formData.note}
                      onChange={handleInputChange}
                    />
                  </div>
                  <Button className="primarybtn w-full" onClick={handleSubmit}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductInquiry;
