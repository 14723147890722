import React from "react";
import { returnReturnData } from "../../utils/mock";

const RefundReturn = () => {
  return (
    <>
      <div className="commna_herosec position-relative">
        <div className="container">
          <div className="text-white">
            <h1 className="text-[50px] md:text-[40px] sm:text-[32px] tsm:text-[24px] font-bold ">
              Refund & Return
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="comman_space w-[75%] tsm:w-full m-auto">
          {returnReturnData?.map((item, i) => (
            <div key={i} className="mb-5">
              <h4 className="text-[28px] sm:text-[24px] tsm:text-xl font-semibold text-primary-50 ">
                {item?.title}
              </h4>
              {item?.content?.map((desc, j) => (
                <p key={j} className="text-black text-lg tsm:text-sm">
                  {desc}
                </p>
              ))}
            </div>
          ))}

          <h4 className="text-[32px] sm:text-[24px] tsm:text-[20px] font-semibold text-primary-50 ">
            Contact Information
          </h4>
          <p className="text-black text-lg tsm:text-sm">
            If you have any questions or concerns regarding these terms, please
            contact us at{" "}
            <a href="mailto:infoawriabindia@gmail.com">
              infoawriabindia@gmail.com
            </a>{" "}
            or <a href="https://t.me/+971585297787">call +91 7357166447.</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default RefundReturn;
