import React from "react";
import { Icon } from "../../ui";
import { useLocation } from "react-router-dom";
import ProductCard from "./ProductCard";

const ProductDetail = () => {
  const location = useLocation();
  const blog = location?.state?.data;
  return (
    <>
      <div className="commna_herosec position-relative">
        <div className="container">
          <div className="text-white">
            <h1 className="text-[50px] md:text-[40px] sm:text-[32px] tsm:text-[24px] font-bold ">
              Product Detail
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        <section className="comman_space">
          <div className="flex gap-8 sm:flex-col sm:gap-4">
            <div className="w-1/2 sm:w-full">
              <div className="sticky top-32 sm:relative sm:top-0">
                <Icon
                  name={blog?.icon}
                  className="img-fluid m-auto rounded-lg"
                />
              </div>
            </div>
            <div className="w-1/2 sm:w-full">
              <div>
                <h4 className="text-[40px] md:text-[32px] sm:text-[26px] tsm:text-lg font-light py-2 text-primary-50">
                  {blog?.title}
                </h4>
                <p className="text-base sm:text-sm text-primary-100 pb-8 sm:pb-4">
                  {blog?.discription}
                </p>
                <div className="sm:pt-4">
                  {blog?.discriptionpage?.map((desc, j) => (
                    <p className="pb-4 text-black  sm:text-sm tsm:text-sm">
                      {desc}
                    </p>
                  ))}
                </div>
                {blog?.poins?.map((item, i) => (
                  <div key={i}>
                    <h4 className="font-medium text-lg tsm:text-base mt-4 text-primary-main">
                      {item?.title}
                    </h4>
                    <ul className="ml-5 mt-2">
                      {item?.discription?.map((desc, j) => (
                        <li
                          key={j}
                          className="mb-2 list-disc tsm:text-sm text-black"
                        >
                          {desc}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className="comman_space !pt-0">
          <ProductCard />
        </section>
      </div>
    </>
  );
};

export default ProductDetail;
