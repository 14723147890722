import React, { useState } from "react";
import { contactPageInfo } from "../../utils/mock";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const [message, setMessage] = useState("");

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("http://192.168.132.62:8000/api", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setMessage("Form submitted successfully!");
        setFormData({
          firstName: "",

          email: "",
          phoneNumber: "",
          message: "",
        });

        // Clear the message after 3 seconds
        setTimeout(() => setMessage(""), 3000);
      } else {
        setMessage("There was an error submitting the form.");
        setTimeout(() => setMessage(""), 3000);
        setFormData({
          firstName: "",

          email: "",
          phoneNumber: "",
          message: "",
        });
      }
    } catch (error) {
      setMessage("There was an error submitting the form.");
      setTimeout(() => setMessage(""), 3000);
      setFormData({
        firstName: "",
        email: "",
        phoneNumber: "",
        message: "",
      });
    }
  };

  return (
    <>
      <div className="commna_herosec position-relative">
        <div className="container">
          <div className="text-white">
            <h1 className="text-[50px] md:text-[40px] sm:text-[32px] tsm:text-[24px] font-bold ">
              Contact
            </h1>
            <p className="tsm:text-sm">
              Let’s Connect and Transform Your Payment Experience
            </p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="comman_space">
          <div className="flex items-center md:flex-col sm:flex-col gap-4">
            <div className="w-1/2 md:w-full sm:w-full">
              <div className="commantext ">
                <span className="text-primary-main">Contact Us</span>
                <h4 className="!text-primary-main !font-extralight">
                  Get In Touch
                </h4>
                <p>We're Here to Help – Let's Make Payments Simple Together!</p>
              </div>
              <div className="grid grid-cols-2 tsm:grid-cols-1 gap-6 pt-10">
                {contactPageInfo.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-4 tsm:gap-2"
                  >
                    <div className="bg-primary-100 text-white p-4 rounded-md text-[30px] tsm:text-2xl tsm:p-3">
                      {item.icon}
                    </div>
                    <div className="text-primary-50">
                      <span className="font-semibold text-xl tsm:text-lg">
                        {item.title}
                      </span>
                      <p className="tsm:text-sm text-base">
                        <a
                          href={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.content}
                        </a>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-1/2 md:w-full sm:w-full comman_shadow p-8 sm:p-6 tsm:p-4">
              <form className="contact_form" onSubmit={handleSubmit}>
                <div className="form_field">
                  <div className="relative">
                    {/* <HiOutlineUser className="icon_position" /> */}
                    <input
                      type="text"
                      name="firstName"
                      className="form-control"
                      value={formData.firstName}
                      onChange={handleChange}
                      placeholder="Full Name"
                    />
                  </div>
                </div>
                <div className="form_field">
                  <div className="relative">
                    {/* <HiOutlineEnvelope className="icon_position" /> */}
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="Your Email"
                    />
                  </div>
                </div>
                <div className="form_field">
                  <div className="relative">
                    {/* <IoCallOutline className="icon_position" /> */}
                    <input
                      type="tel"
                      name="phoneNumber"
                      className="form-control"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      placeholder="Phone Number"
                    />
                  </div>
                </div>
                <div className="form_field">
                  <textarea
                    name="message"
                    className="form-control"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Your Message"
                  />
                </div>
                <button
                  type="submit"
                  className="primarybtn !px-10"
                >
                  Submit
                </button>
              </form>

              {message && <div className="text-green-500">{message}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
