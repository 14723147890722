import React from "react";
import { Icon } from "../../ui";
import { NavLink, useNavigate } from "react-router-dom";

import { contactInfo, contactPageInfo, footerData } from "../../utils/mock";
import Button from "../../ui/elements/Button";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <>
      <footer>
        <div className="footer">
          <div className="container">
            <div className="flex md:grid md:grid-cols-2 sm:grid sm:grid-cols-2 gap-4 mb:gap-2 py-8">
              <div className="tsm:col-span-2 w-[35%] md:w-full sm:w-full">
                <NavLink to={"/"}>
                  <Icon
                    name="Logo"
                    className="img-fluid mb-3 w-[80px] sm:w-[75px] tsm:w-[60px]"
                  />
                </NavLink>
                <p className="tsm:text-sm text-primary-white font-normal">
                  Owned and operated by AWRIAB WALL INDIA (OPC) PRIVATE LIMITED.
                  Awriabindia.com specializes in manufacturing durable,
                  eco-friendly bricks and concrete blocks for the construction
                  industry.
                </p>
              </div>
              <div className="w-[30%] tsm:col-span-2 md:w-full sm:w-full flex justify-around">
                {footerData?.map((data) => {
                  return (
                    <div className="my-2" key={data.title}>
                      <div className="link-detail">
                        <h6 className="font-semibold pb-2 text-lg text-primary-white">
                          {data.title}
                        </h6>
                        {data.links.map((link) => (
                          <p className="sm:text-sm text-primary-100 font-normal pb-2">
                            <NavLink to={link.href}>{link.text}</NavLink>
                          </p>
                        ))}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="my-2 tsm:col-span-2 w-[35%] md:w-full sm:w-full">
                <div className="link-detail">
                  <h6 className="font-semibold text-lg text-primary-white pb-2">
                    Tell Us
                  </h6>
                  <p className="sm:text-sm text-primary-100 font-normal pb-2">
                    In case of any query, please Tell Here{" "}
                    <a
                      href="https://t.me/+917357166447"
                      target="_blank"
                      className="text-primary-main"
                    >
                      Message Us
                    </a>
                  </p>

                  {contactPageInfo.map((item, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-2 tsm:gap-2 pb-2"
                    >
                      <div className="text-primary-main rounded-md text-[24px] tsm:text-[20px]">
                        {item.icon}
                      </div>
                      <div className="text-primary-white">
                        {/* <span className="font-medium text-lg tsm:text-lg">
                          {item.title}
                        </span> */}
                        <p className="tsm:text-sm text-base">
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.content}
                          </a>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="flex justify-between tsm:flex-col tsm:items-center tsm:gap-2 py-4  border-t border-primary-white">
            <p className="text-sm tsm:text-xs text-primary-white">
              Copyright @2024 Awriabindia. All Rights Reserved
            </p>
            <p className="text-sm tsm:text-xs text-primary-white">
              GST NO : 07ABACA9612L1ZJ
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
