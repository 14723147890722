import React from "react";
import ProductCard from "./ProductCard";

const Product = () => {
  return (
    <>
      <div className="commna_herosec position-relative">
        <div className="container">
          <div className="text-white">
            <h1 className="text-[50px] md:text-[40px] sm:text-[32px] tsm:text-[24px] font-bold ">
              All Products
            </h1>
            <p className="tsm:text-sm">Durable Solutions for Every Need</p>
          </div>
        </div>
      </div>
      <div className="container">
        <section className="comman_space">
          <ProductCard />
        </section>
      </div>
    </>
  );
};

export default Product;
