import {
  FaTelegramPlane,
  // FaFacebook,
  // FaInstagram,
  // FaLinkedin,
  // FaTwitter,
  FaWhatsapp,
  // FaYoutube,
} from "react-icons/fa";
import { IoLocationOutline, IoMailOutline } from "react-icons/io5";

export const footerData = [
  {
    title: "Company",
    links: [
      { text: "Home", href: "/" },
      // { text: "Feature", href: "/feature" },
      { text: "About Us", href: "/about-us" },
      { text: "Product", href: "/product" },
      { text: "Contact Us", href: "/contact-us" },
    ],
  },
  {
    title: "Legal",
    links: [
      { text: "Privacy Policy", href: "/privacy-policy" },
      { text: "Terms & Conditions", href: "/terms-condition" },
      { text: "Refund & Return", href: "/refund-return" },
      { text: "Cancellation Policy", href: "/cancellation-policy" },
    ],
  },
  // {
  //   title: "Cryptos",
  //   links: [
  //     { text: "What is Crypto", href: "#" },
  //     { text: "Bitcoin", href: "#" },
  //     { text: "Ether", href: "#" },
  //     { text: "XRP", href: "#" },
  //   ],
  // },
];

export const socialLinks = [
  // { icon: <FaInstagram />, path: "/" },
  // { icon: <FaFacebook />, path: "/" },
  // { icon: <FaTwitter />, path: "/" },
  // { icon: <FaLinkedin />, path: "/" },
  // { icon: <FaYoutube />, path: "/" },
  { icon: <FaWhatsapp />, path: "/" },
];

export const contactInfo = [
  {
    icon: <IoMailOutline />,
    title: "Email Us",
    content: "infoawriabindia@gmail.com",
    link: "mailto:infoawriabindia@gmail.com",
  },
  {
    icon: <FaTelegramPlane />,
    title: "Message Us",
    content: "+91 7357166447",
    link: "https://t.me/+917357166447",
  },
  {
    icon: <IoLocationOutline />,
    title: "Location",
    content:
      "A-27 First Floor,Old Plot No. 35&36,Guru Nanak Pura Laxmi Naghar,New Delhi, Shahdara, Delhi, 110092",
    link: "https://maps.app.goo.gl/fzgQ85kLk3frC1mq6",
  },
];

export const contactPageInfo = [
  // {
  //   icon: <GoStopwatch />,
  //   title: "Opening",
  //   content: "Mon - Sat 08:00 - 20:00",
  //   link: "#",
  // },
  {
    icon: <IoMailOutline />,
    title: "Email Us",
    content: "infoawriabindia@gmail.com",
    link: "mailto:infoawriabindia@gmail.com",
  },
  {
    icon: <FaTelegramPlane />,
    title: "Message Us",
    content: "+91 7357166447",
    link: "https://t.me/+917357166447",
  },
  {
    icon: <IoLocationOutline />,
    title: "Location",
    content:
      "A-27 First Floor,Old Plot No. 35&36,Guru Nanak Pura Laxmi Nagar,New Delhi, Shahdara, Delhi, 110092",
    link: "https://maps.app.goo.gl/fzgQ85kLk3frC1mq6",
  },
];

export const privacyPolicyData = [
  {
    title: "Who We Are",
    content: ["Welcome to Awriabindia"],
  },
  {
    title: "1. Introduction",
    content: [
      "AWRIAB WALL INDIA (OPC) PRIVATE LIMITED (“we,” “us,” or “our”) respects your privacy and is committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and protect your data when you visit our website.",
    ],
  },
  {
    title: "2. Information We Collect",
    content: ["We may collect the following types of information:"],
  },
  {
    title: "3. Use of Information",
    content: [
      "We use the information collected for the following purposes:",
      "Personal Information: Name, contact details (email, phone number), address, etc., that you provide through forms or inquiries.",
      "Usage Data: Information on how you use our website, such as pages visited, time spent, and interactions.",
      "Cookies: Small files that gather certain data to improve user experience. You may disable cookies in your browser settings.",
    ],
  },
  {
    title: "4. Information Sharing and Disclosure",
    content: [
      "We do not sell, rent, or share your personal information with third parties except in the following cases:",
      "Service Providers: We may share information with third-party service providers who assist us in delivering services, provided they agree to strict data protection obligations.",
      "Legal Requirements: We may disclose information if required by law or in response to valid legal processes.",
    ],
  },
  {
    title: "5. Data Security",
    content: [
      "We take reasonable measures to secure your personal information. However, no method of transmission over the internet is 100% secure, and we cannot guarantee absolute security.",
    ],
  },
  {
    title: "6. Your Rights",
    content: [
      "You may have the following rights regarding your personal data:",
      "Access: Request access to the personal information we hold about you.",
      "Correction: Request corrections to any inaccurate data.",
      "Deletion: Request deletion of your personal data in certain circumstances.",
      "Opt-out: Unsubscribe from marketing communications at any time by following the instructions in each communication.",
    ],
  },
  {
    title: "7. Links to Other Websites",
    content: [
      "Our website may contain links to external websites. We are not responsible for the content or privacy practices of these other sites.",
    ],
  },
  {
    title: "8. Changes to This Privacy Policy",
    content: [
      "We may update this Privacy Policy from time to time. Any changes will be posted on this page, and your continued use of the website indicates acceptance of the revised policy.",
    ],
  },
];

export const termsConditionData = [
  {
    // title: "Comments",
    content: [
      "When visitors leave comments on the site, we collect the data shown in the comments form, the visitor’s IP address, and browser user agent string to help spam detection. An anonymized string created from your email address (also called a hash) may be provided to the Gravatar service to see if you are using it. After approval of your comment, your profile picture is visible to the public in the context of your comment.",
    ],
  },
  {
    title: "Media",
    content: [
      "If you upload images to the website, please avoid including embedded location data (EXIF GPS) as visitors to the website can download and extract any location data from images.",
    ],
  },
  {
    title: "Cookies",
    content: [
      "If you leave a comment on our site, you may opt-in to saving your name, email address, and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.",
      ,
      "If you visit our login page, a temporary cookie is set to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.",
      `When you log in, several cookies are set up to save your login information and screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select "Remember Me", your login will persist for two weeks. If you log out of your account, the login cookies will be removed.`,
    ],
  },
  {
    title: "Embedded Content from Other Websites",
    content: [
      "Articles on this site may include embedded content (e.g., videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website",
      "These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.",
    ],
  },
  {
    title: "Who We Share Your Data With",
    content: [
      "If you request a password reset, your IP address will be included in the reset email.",
    ],
  },
  {
    title: "How Long We Retain Your Data",
    content: [
      "If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.",
      "For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information.",
    ],
  },
  {
    title: "What Rights You Have Over Your Data",
    content: [
      "If you have an account on this site or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.",
    ],
  },
  {
    title: "Where Your Data Is Sent",
    content: [
      "Visitor comments may be checked through an automated spam detection service.",
    ],
  },
];

export const returnReturnData = [
  {
    title: "1. Overview",
    content: [
      "Our refund and returns policy lasts 30 days from the date of purchase. If 30 days have passed since your purchase, unfortunately, we can’t offer a refund or exchange. To be eligible for a return, your item must be unused and in the same condition that you received it, including the original packaging.",
    ],
  },
  {
    title: "2. Non-Returnable Items",
    content: [
      "Certain items are exempt from being returned:",
      "Gift cards",
      "Perishable goods (if applicable)",
      "Customized or personalized items (if applicable)",
      "Items marked as final sale at the time of purchase",
    ],
  },
  {
    title: "3. Refund Process",
    content: [
      "Once your return is received and inspected, we will send you an email notification regarding the approval or rejection of your refund. If approved, your refund will be processed, and a credit will automatically be applied to your original payment method within a specified number of days.",
    ],
  },
  {
    title: "4. Late or Missing Refunds",
    content: [
      "If you haven’t received a refund yet:",
      "Check your bank account again.",
      "Contact your credit card company; it may take some time before your refund is officially posted.",
      "If you've done all of this and still have not received your refund, please contact us at infoawriabindia@gmail.com.",
    ],
  },
  {
    title: "5. Exchanges",
    content: [
      `We only replace items if they are defective or damaged. If you need to exchange an item for the same product, please contact us at infoawriabindia@gmail.com or call +91 9910985059 for further instructions.`,
    ],
  },
  {
    title: "6. Return Shipping",
    content: [
      "You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund if applicable. For high-value items, we recommend using a trackable shipping service or purchasing shipping insurance.",
    ],
  },
];

export const orderCancelationData = [
  {
    title: "1. Order Cancellations",
    content: [
      "You may cancel your order within 24 hours of placing it to receive a full refund. After 24 hours, cancellation requests may not be guaranteed as the order may already be in processing or shipping stages.",
      "To cancel an order, please contact us at infoawriabindia@gmail.com or call +91 9910985059 with your order details.",
    ],
  },
  {
    title: "2. Non-Cancellable Items",
    content: [
      "Certain items may not be eligible for cancellation, including:",
      "Customized or personalized items",
      "Items marked as final sale at the time of purchase",
      "Perishable goods (if applicable)",
    ],
  },
  {
    title: "3. Refunds on Cancelled Orders",
    content: [
      "If your cancellation request is approved, a refund will be processed to your original payment method. Refunds may take several business days to reflect, depending on your payment provider.",
      "Any processing fees incurred during the original transaction may be non-refundable.",
    ],
  },
  {
    title: "4. Modifications to Orders",
    content: [
      "If you wish to modify your order instead of canceling it, please contact us within 24 hours of placing the order. We will try our best to accommodate changes based on availability and processing status.",
    ],
  },
];
