import React from "react";
import { productData } from "../home/mock";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../ui";
import { BsArrowRightCircle } from "react-icons/bs";

const ProductCard = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="commantext w-[50%]  md:w-[75%] sm:w-[60%] tsm:w-full m-auto text-center">
        <h4>
          <span>Latest</span> Products For Custmer
        </h4>
        <p>
          Hey there pro traders, check out these articles with tips to take your
          trading game to the next level!
        </p>
      </div>
      <div className="grid grid-cols-3 sm:grid-cols-2 tsm:grid-cols-1  gap-6 pt-8 tsm:pt-2">
        {productData?.map((data, index) => {
          return (
            <div
              key={index}
              className="product_card comman_shadow h-full"
              onClick={() => {
                navigate("/product-detail", { state: { data } });
                window.scrollTo(0, 0);
              }}
            >
              <div className="border-b border-primary-main">
                <Icon name={data?.icon} className="m-auto w-60" />
              </div>
              <div className="p-4 flex justify-between items-start">
                <h4 className="text-primary-50 text-xl font-semibold sm:text-lg tsm:text-base">
                  {data?.title}
                </h4>
                <div
                  onClick={() => {
                    navigate("/product-detail", { state: { data } });
                    window.scrollTo(0, 0);
                  }}
                >
                  <BsArrowRightCircle size={32} color="var(--primary-100)" />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ProductCard;
