import { useState, useEffect } from 'react';

const useWindowDimensions = () => {
    const [media, setMedia] = useState({});

    useEffect(() => {
        const handleResize = () => {
          setMedia({ width: window.innerWidth, height: window.innerHeight });
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    
        // eslint-disable-next-line
      }, []);

  return media;
};

export default useWindowDimensions;
