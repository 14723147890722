import React, { useState } from "react";
import Button from "../../ui/elements/Button";
import { Icon } from "../../ui";
import {
  productData,
  featureData,
  blogData,
  aboutPoints,
  accordionFullData,
  backboneData,
  ourClientsData,
} from "./mock";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination as SwiperPagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import { useNavigate } from "react-router-dom";
import { BsArrowRight, BsArrowRightCircle } from "react-icons/bs";
import { FaCheck } from "react-icons/fa";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const Home = () => {
  const navigate = useNavigate();
  const [openIndex, setOpenIndex] = useState(null);
  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };


  return (
    <>
      {/* NAVBAR */}

      <section>
        <div className="hero-sec position-relative">
          <div className="container">
            <div className="flex sm:flex-col items-center gap-4 justify-center heroImg">
              <div className="w-1/2 sm:w-full">
                <h1>Strength and Quality You Can Build On</h1>
                <p>
                  Precision-engineered concrete blocks for strength, durability,
                  and sustainable construction solutions. From homes to
                  high-rises, we’re here to shape a solid future.
                </p>
                <div className="mt-4">
                  <div>
                    <Button
                      class="whitebtn"
                      onClick={() => {
                        navigate("/contact-us");
                      }}
                    >
                      Get Started
                    </Button>
                  </div>
                </div>
              </div>

              <div className="w-1/2 sm:w-full">
                <Icon
                  name="Heroimg"
                  className="img-fluid w-[450px] sm:w-[350px] m-auto"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="comman_space bg-primary-50 ">
        <div className="container relative z-[1]">
          <div className="flex justify-between sm:flex-col md:rounded-3xl gap-6">
            <div className="text-primary-white w-[50%] md:w-[75%] sm:w-full">
              <h4 className="text-[40px] sm:text-[32px] tsm:text-[24px] font-semibold">
                Built on Trust, Driven by Quality
              </h4>
              <p>
                Our mission is to provide high-quality concrete blocks that
                support sustainable, resilient structures. With precision,
                innovation, and a commitment to excellence, we help builders
                create reliable foundations that stand the test of time. Our
                concrete solutions are crafted to meet diverse needs, offering
                strength, durability, and trust you can build on.
              </p>
            </div>
            <div>
              <Button
                class="whitebtn"
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                About Us
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className="comman_space">
        <div className="container">
          <div className="flex gap-8 items-center md:flex-col sm:flex-col">
            <div className="w-1/2 sm:w-full md:w-full relative">
              <Icon name="aboutPage" className="img-fluid m-auto rounded-lg" />
              <div className="grid grid-cols-1 gap-2 absolute -top-2 -right-2 ">
                {aboutPoints?.map((item, index) => (
                  <div
                    key={index}
                    className="text-primary-main comman_shadow px-6 border-b-2 border-primary-main py-10 tsm:py-4 text-center bg-primary-white"
                  >
                    <p className="font-bold  text-xl">{item?.phase}</p>
                    <h4 className="text-base">{item?.text}</h4>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-1/2 sm:w-full md:w-full">
              <div className="">
                <span className="border-b border-primary-main">About Us</span>
                <h4 className="text-primary-50 text-3xl sm:text-xl tsm:text-lg font-bold">
                  Best Quality Refractory Brick Manufacturers in India
                </h4>
                <span className="text-primary-main py-4 inline-block font-semibold">
                  <i>
                    Refractory Products, Provides Comprehensive Solutions to the
                    Clients
                  </i>
                </span>
                <p className=" text-black text-lg tsm:text-sm text-primary-50">
                  We are the best when it comes to Refractory Bricks in India!
                  Not only in Manufacturing but we’re also best when it comes to
                  supplying and trading. We’ve been doing this for over 5 years
                  and are your trusted source for tough, reliable bricks.
                </p>
                <p className="py-4 text-black text-lg tsm:text-sm text-primary-50">
                  Our special bricks are made from good stuff like fireclay,
                  alumina, magnesia, and zirconia. We’ve got a bunch, including
                  firebricks, insulating bricks, and cool monolithic, all
                  designed to fit what you need.
                </p>
                <p className="py-4 text-black text-lg tsm:text-sm text-primary-50">
                  But it’s not just about bricks! We’ve got other heat-resistant
                  materials and services, like smart design, putting things in
                  place, and keeping them working well. Our team of experienced
                  folks is here to help you find the right solution for your
                  needs.
                </p>

                <div className="mt-4">
                  <Button
                    className="primarybtn !py-2 "
                    onClick={() => {
                      navigate("/about-us");
                    }}
                  >
                    Read More
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="commantext text-center m-auto w-[50%] md:w-[70%] sm:w-[85%] tsm:w-full">
            <h4>Engineered for Excellence in Every Block</h4>
            <p>
              High-strength, durable concrete blocks engineered for lasting
              performance in every build.
            </p>
          </div>

          <div className="feature_grid pt-5">
            {featureData?.map((item, index) => {
              return (
                <div class="feature_boxcard text-center" key={index}>
                  <div className="bg-primary-main rounded-full w-fit m-auto p-6 tsm:p-4">
                    <Icon
                      name={item?.icon}
                      className="img-fluid w-[50px] tsm:w-[35px]"
                    />
                  </div>
                  <div className="pt-4">
                    <h5 className="text-primary-50 text-2xl md:text-lg tsm:text-lg font-semibold pb-2">
                      {item?.title}
                    </h5>
                    <p className="text-primary-50 text-base sm:text-sm">
                      {item?.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <section className="comman_space" id="review">
        <div className="review_main">
          <div className="p-24 md:p-16 sm:p-12 tsm:p-6">
            <div className="commantext text-center m-auto w-[50%] md:w-[70%] sm:w-[85%] tsm:w-full">
              <span className="!text-white border-b border-primary-main">
                What we’re offering
              </span>
              <h4 className="!text-white !font-extrabold">
                Affordable Refractory Brick Provider
              </h4>
            </div>
            <div className="blog_swiper pt-8 relative">
              <Swiper
                centeredSlides={false}
                spaceBetween={20}
                speed={900}
                parallax
                rewind
                loop
                effect="slide"
                freeMode
                grabCursor={true}
                watchSlidesProgress
                slidesPerView={3}
                breakpoints={{
                  1300: { slidesPerView: 4 },
                  1280: { slidesPerView: 3 },
                  769: { slidesPerView: 1 },
                  576: { slidesPerView: 2 },
                  0: { slidesPerView: 1 },
                }}
                autoplay={{
                  delay: 19000,
                }}
                pagination={{ clickable: true, el: ".swiper-pagination" }}
                modules={[Autoplay, SwiperPagination]}
              >
                {productData?.map((data, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div
                        className="product_card  h-full"
                        onClick={() => {
                          navigate("/product-detail", { state: { data } });
                        }}
                      >
                        <div className="border-b border-primary-main">
                          <Icon name={data?.icon} className="m-auto w-60" />
                        </div>
                        <div className="p-4 flex justify-between items-start">
                          <h4 className="text-primary-50 text-xl font-semibold sm:text-lg tsm:text-base">
                            {data?.title}
                          </h4>
                          <div
                            onClick={() => {
                              navigate("/product-detail", { state: { data } });
                            }}
                          >
                            <BsArrowRightCircle
                              size={32}
                              color="var(--primary-100)"
                            />
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-primary-200 aibg">
        <div className="container">
          <div className="flex justify-center my-2">
            <div className="w-1/2 sm:w-[75%] tsm:w-full text-center">
              <div className="commantext ">
                <h4>Your Trusted Partner in Quality Construction</h4>
              </div>
            </div>
          </div>

          <div className="flex gap-10 md:flex-col items-center sm:flex-col pt-8">
            <div className="w-1/2 md:w-full sm:w-full bg-primary-100 rounded-xl p-8 tsm:p-4">
              <div className="grid grid-cols-1 gap-2">
                {backboneData.map((item, index) => (
                  <div key={index} className="pb-4 text-primary-white">
                    <div className="flex gap-2 items-center pb-2">
                      <div>
                        <div className="flex items-center justify-center h-10 w-10 rounded-full bg-primary-black">
                          <MdKeyboardDoubleArrowRight size={26} />
                        </div>
                      </div>
                      <h5 className=" text-xl md:text-lg tsm:text-base font-semibold ">
                        {item?.title}
                      </h5>
                    </div>
                    <p className="text-base sm:text-sm">{item?.description}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-1/2 md:w-full sm:w-full">
              <div className="">
                <span className="border-b border-primary-main">
                  INDUSTRIAL GROUPS
                </span>
                <h4 className="text-primary-50 text-3xl sm:text-xl tsm:text-lg font-bold">
                  Best Quality Refractory Brick Manufacturers in India
                </h4>
                <span>
                  <i>
                    Refractory Products, Provides Comprehensive Solutions to the
                    Clients
                  </i>
                </span>
                <p className="py-4 text-black text-lg tsm:text-sm text-primary-50">
                  Are you searching for a trusted and reliable refractory brick
                  manufacturer in India? Look no further than Jindal, a leading
                  refractory brick manufacturer in India dedicated to meeting
                  the diverse needs of various industries. At Jindal’s
                  refractory industry, our expert team is committed to assisting
                  you in discovering specialized refractory solutions tailored
                  to your industrial requirements. Whether you aim to enhance
                  plant performance or ensure the success of your next project,
                  you can rely on us for top-quality refractory materials and
                  services. Jindal, a prominent refractory{" "}
                  <b>fire bricks manufacturer</b> , was established in 2018 and
                  holds ISO 9001:2015 certification. Located in Gujarat, our
                  Jindal refractory industry has gained a solid global presence,
                  serving numerous industries for over five years.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="comman_space">
        <div className="container">
          <div className="commantext w-[40%] sm:w-[60%] tsm:w-full text-center m-auto">
            <span className="border-b border-primary-main">WORK PROCESS</span>
            <h4 className="font-extrabold">Our Clients</h4>
            {/* <div className="relative tsm:flex tsm:justify-end">
              <div>
                <Button
                  class="primarybtn"
                  onClick={() => {
                    navigate("/blog");
                  }}
                >
                  View All Blog
                </Button>
              </div>
            </div> */}
          </div>

          <div className="client_swiper pt-8 relative">
            <Swiper
              centeredSlides={false}
              spaceBetween={20}
              speed={900}
              parallax
              rewind
              loop
              effect="slide"
              freeMode
              grabCursor={true}
              watchSlidesProgress
              slidesPerView={3}
              breakpoints={{
                1300: { slidesPerView: 5 },
                1280: { slidesPerView: 5 },
                769: { slidesPerView: 4 },
                576: { slidesPerView: 3 },
                0: { slidesPerView: 2 },
              }}
              autoplay={{
                delay: 19000,
              }}
              pagination={{ clickable: true, el: ".swiper-pagination" }}
              modules={[Autoplay, SwiperPagination]}
            >
              {ourClientsData?.map((data, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="h-full">
                      <Icon
                        name={data?.icon}
                        className="w-40 m-auto tsm:w-28"
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </section>

      <section >
        <div className="container">
          <div className="flex justify-center my-2">
            <div className="w-1/2 sm:w-[80%] tsm:w-full text-center">
              <div className="commantext">
                <h4>Your Questions, Our Expertise</h4>
                <p>
                  Find answers to your questions about our concrete blocks,
                  including quality, applications, and production processes.
                </p>
              </div>
            </div>
          </div>
          <div className="flex gap-4 md:flex-col sm:flex-col justify-center py-6 items-center">
            <div className="w-1/2 md:w-full sm:w-full">
              <div className="faq-container">
                {accordionFullData.map((item, index) => (
                  <div key={index} className="faq-item">
                    <div
                      className={`faq-question ${
                        openIndex === index ? "active" : ""
                      }`}
                      onClick={() => toggleFAQ(index)}
                    >
                      <h3>{item.title}</h3>
                    </div>
                    <div
                      className={`faq-answer ${
                        openIndex === index ? "open" : "closed"
                      }`}
                    >
                      {item.content}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-1/2 md:w-full sm:w-full">
              <Icon
                name="faq"
                className="w-[450px] sm:w-[350px]  tsm:w-[280px] m-auto "
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
