import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Icon } from "../../ui";

const InquiryDetail = () => {
  const [inquiries, setInquiries] = useState();

  const inquiryData = inquiries?.data?.map((item) => ({
    "Basic Information": [
      { label: "Customer Name", value: item.customer_name },
      { label: "Product Name", value: item.product_name },
      { label: "Accepted Date", value: item.accepted_date },
    ],
    "Contact Details": [
      { label: "Phone Number", value: item.phone_number },
      { label: "Email", value: item.email },
      { label: "Address", value: item.address },
    ],
    "Other Details": [
      { label: "Note", value: item.note },
      { label: "Status", value: "Our executive will contact you shortly" },
    ],
  }));
  const fetchInquiries = async () => {
    const token = localStorage.getItem("accessToken");

    try {
      const response = await fetch(
        "https://api.awriabindia.com/api/product-inquiry-list",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      if (response.ok) {
        setInquiries(data);
      } else {
        toast.error(data?.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchInquiries();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className="commna_herosec position-relative">
        <div className="container">
          <div className="text-white">
            <h1 className="text-[50px] md:text-[40px] sm:text-[32px] tsm:text-[24px] font-bold ">
              Your Inquiry
            </h1>
          </div>
        </div>
      </div>
      <section className="comman_space">
        <div className="container">
          {inquiries?.data?.length > 0 ? (
            <>
              {inquiryData?.map((inquiry, index) => (
                <div
                  key={index}
                  className="grid grid-cols-3 sm:grid-cols-2 tsm:grid-cols-1 border border-primary-100 p-6 mb-4 relative"
                >
                  {Object.entries(inquiry).map(([sectionTitle, fields]) => (
                    <div key={sectionTitle} className="flex flex-col gap-3">
                      {/* <h3 className="text-primary-main text-lg font-semibold mb-2 border-b border-primary-100 pb-2 ">
                        {sectionTitle}
                      </h3> */}
                      {fields.map((field, idx) => (
                        <div key={idx}>
                          <h4 className="text-primary-50 text-md font-semibold">
                            {field.label}
                          </h4>
                          <p
                            className={` ${
                              field.label === "Status"
                                ? "text-green-600"
                                : "text-primary-100"
                            }`}
                          >
                            {field.value}
                          </p>
                        </div>
                      ))}
                    </div>
                  ))}

                  {/* <div className="absolute bottom-0 right-0 text-green-600 py-1 px-2">
                    <h4 className="text-md sm:text-sm font-normal">
                    Our executive will contact you shortly
                    </h4>
                  </div> */}
                </div>
              ))}
            </>
          ) : (
            <div className="text-center">
              <Icon
                name="nodatafound"
                className="img-fluid m-auto w-[250px] sm:w-[200px] tsm:w-[150px]"
              />
              <p className="text-primary-50 text-[32px] sm:text-[28px] tsm:text-[24px] mt-4 font-semibold">
                No inquiry data available
              </p>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default InquiryDetail;
