import React, { useEffect, useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Icon } from "../../ui";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../useWindowDimensions";
import Button from "../../ui/elements/Button";
import { FaTelegramPlane } from "react-icons/fa";
import { toast,ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    // const sectionsForanimation = [
    //   {
    //     id: "trade",
    //     class: "feature_main",
    //     animationClass: "swing-in-top-fwd",
    //   },
    //   {
    //     id: "whychoos",
    //     class: "whychoos_main",
    //     animationClass: "scale-in-hor-center",
    //   },
    //   {
    //     id: "review",
    //     class: "review_main",
    //     animationClass: "scale-in-center",
    //   },
    //   {
    //     id: "joius",
    //     class: "joius_img",
    //     animationClass: "tilt-in-fwd-tl",
    //   },
    // ];

    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);

      const windowHeight = window.innerHeight;
      const threshold = width < 525 ? windowHeight * 0.9 : windowHeight * 0.7;

      // sectionsForanimation.forEach((section) => {
      //   const sectionElement = document.getElementById(section.id);
      //   const element = document.querySelector("." + section.class);
      //   const rect = sectionElement?.getBoundingClientRect();
      //   if (rect?.top <= threshold) {
      //     element.classList.add(section.animationClass);
      //   } else if (rect?.top <= 0) {
      //     element.classList.remove(section.animationClass);
      //   }
      // });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    if (isOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
      }, 400); // Match the duration of the reverse animation
    } else {
      setIsOpen(true);
    }
  };

  const navLinksItems = [
    { path: "/", label: "Home" },
    { path: "/product", label: "Product" },
    // { path: "/feature", label: "Feature" },
    { path: "/about-us", label: "About Us" },
    { path: "/contact-us", label: "Contact Us" },
    // { path: "/login", label: "Login" },
  ];

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setIsLoggedIn(token);
  }, [localStorage.getItem("accessToken")]);

  const handleLogout = () => {
    // Remove access token from localStorage
    localStorage.removeItem("accessToken");
    toast.success("You have successfully logged out!");
    setIsLoggedIn(false);
    navigate("/login");
  };

  const isNotHome = location.pathname !== "/";
  return (
    <>
    <ToastContainer/>
      <header
        // className={` fixed top-0 left-0 right-0 z-[11] ${
        //   isScrolled
        //     ? "swing-in-top-fwd headershadow bg-primary-50"
        //     : isOpen
        //     ? "blurbg"
        //     : "bg-transparent"
        // }`}
        className={` fixed top-0 left-0 right-0 z-[11]  ${
          isScrolled
            ? "swing-in-top-fwd bg-primary-50 border-b border-primary-borderlight"
            : ""
        }`}
      >
        <div className="container">
          <div className="navbar">
            <div
              className="navbar-logo"
              onClick={() => {
                navigate("/");
              }}
            >
              <Icon
                name="Logo"
                className="img-fluid  w-[80px] sm:w-[75px] tsm:w-[60px]"
              />
            </div>
            <nav className={`navbar-menu ${width < 992 ? "gap-2" : ""}`}>
              <ul className={`navbar-links text-primary-white`}>
                {width >= 992 ? (
                  <>
                    {navLinksItems?.map((data, index) => (
                      <li
                        key={index}
                        // className={` ${
                        //   location?.pathname === data?.path ? "active" : ""
                        // }`}
                      >
                        <NavLink to={data?.path}>{data?.label}</NavLink>
                      </li>
                    ))}
                  </>
                ) : null}
              </ul>
            </nav>
            {width > 992 ? (
              <div className="relative flex gap-4">
                <Button
                  class={`whitebtn`}
                  onClick={() => {
                    if (isLoggedIn) {
                      navigate("/product-inquiry");
                    } else {
                      navigate("/login");
                    }
                  }}
                >
                  Product Inqury
                </Button>
                {isLoggedIn ? (
                  <>
                    <div class="dropdown">
                      <span className="primarybtn block">Account</span>
                      <div class="dropdown-content">
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            navigate("/inquiry-detail");
                          }}
                        >
                          Profile
                        </p>
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            handleLogout();
                          }}
                        >
                          Log Out
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <Button
                    class={`whitebtn`}
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Login
                  </Button>
                )}
              </div>
            ) : null}

            {width < 992 ? (
              <div
                className={`navbar-toggle p-2 blurbg_icon`}
                onClick={toggleMenu}
              >
                {isOpen && !isClosing ? (
                  <FiX className="text-2xl mb:text-xl" />
                ) : (
                  <FiMenu className="text-2xl mb:text-xl" />
                )}
              </div>
            ) : null}
          </div>
        </div>
      </header>

      {width < 992 ? (
        <>
          <div
            className={`navbar-mainmenu bg-primary-50  ${
              isOpen ? "show" : ""
            } ${isClosing ? "slide-out-right" : ""} ${
              isOpen && !isClosing ? "slide-in-right" : ""
            }`}
          >
            <div className="container">
              <div className="flex flex-col justify-between h-full py-5">
                <div>
                  {navLinksItems?.map((item, index) => {
                    return (
                      <div className="mb-2 dropdown_items" key={index}>
                        <p
                          className={`text-xl ${
                            isScrolled || isNotHome
                              ? "text-primary-white"
                              : "text-primary-white"
                          }`}
                        >
                          <span
                            className={`cursor-pointer ${
                              location?.pathname === item?.path ? "active" : ""
                            }`}
                            onClick={() => {
                              navigate(item?.path);
                              toggleMenu();
                            }}
                          >
                            {item?.label}
                          </span>
                        </p>
                      </div>
                    );
                  })}
                </div>
                <div className="relative">
                  <div className="mb-4">
                    <Button
                      class={`whitebtn`}
                      onClick={() => {
                        if (isLoggedIn) {
                          navigate("/product-inquiry");
                          toggleMenu();
                        } else {
                          navigate("/login");
                          toggleMenu();
                        }
                      }}
                    >
                      Product Inqury
                    </Button>
                  </div>
                  {isLoggedIn ? (
                    <>
                      <div class="dropdown">
                        <span className="primarybtn block">Account</span>
                        <div class="dropdown-content">
                          <p
                            onClick={() => {
                              if (isLoggedIn) {
                                navigate("/product-inquiry");
                                toggleMenu();
                              } else {
                                navigate("/inquiry-detail");
                                toggleMenu();
                              }
                            }}
                          >
                            Profile
                          </p>
                          <p
                            onClick={() => {
                              handleLogout();
                            }}
                          >
                            Log Out
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <Button
                      class={`whitebtn`}
                      onClick={() => {
                        navigate("/login");
                      }}
                    >
                      Login
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Header;
