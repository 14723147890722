export const serviceOfferData = [
  {
    // icon: "tradingIcon2",
    icon: "seviceicon1",
    title: "Innovative Designs",
    description:
      "We stay at the forefront of construction trends, offering blocks that incorporate modern design elements, enabling architects and builders to create unique structures.",
  },
  {
    // icon: "tradingIcon6",
    icon: "seviceicon2",
    title: "Moisture Control",
    description:
      "Our blocks are designed to manage water penetration effectively, reducing the risk of mold and structural damage over time.",
  },
  {
    // icon: "tradingIcon5",
    icon: "seviceicon3",
    title: "Easy to Work With",
    description:
      "Lightweight yet sturdy, our blocks can be handled and installed with ease, speeding up construction timelines and reducing labor costs.",
  },
  {
    // icon: "tradingIcon4",
    icon: "seviceicon4",
    title: "Customizable Options",
    description:
      "We provide a variety of sizes, colors, and finishes, allowing for tailored solutions to meet specific design and functionality needs.",
  },
  {
    // icon: "tradingIcon1",
    icon: "seviceicon5",
    title: "Cost-Effective",
    description:
      "With their durability and low maintenance requirements, our concrete blocks reduce overall project costs while offering exceptional value over time.",
  },
  {
    // icon: "tradingIcon3",
    icon: "seviceicon6",
    title: "Versatile Applications",
    description:
      "Suitable for diverse construction projects, our concrete blocks can be used for walls, foundations, retaining structures, and landscaping, making them a flexible choice for builders.",
  },
];

export const featureData = [
  {
    // icon: "tradingIcon2",
    icon: "feature1",
    title: "High Strength & Durability",
    description:
      "Each block is rigorously tested to ensure it withstands high load-bearing requirements, providing structural stability for decades in any type of build.",
  },
  {
    // icon: "tradingIcon6",
    icon: "feature2",
    title: "Precision Manufacturing",
    description:
      "Produced with state-of-the-art technology for consistent dimensions and smooth surfaces, ensuring faster, easier installation and a seamless finish.",
  },
  {
    // icon: "tradingIcon5",
    icon: "feature3",
    title: "Eco-Friendly Production",
    description:
      "Made with sustainable materials and processes to reduce the carbon footprint, supporting environmentally conscious building practices without compromising quality.",
  },
];

export const pricingData = [
  {
    plan: "Basic",
    ruppes: "$99/",
    time: "Monthly",
    features: [
      "Weekly online meeting",
      "Unlimited learning access",
      "24/7 technical support",
      "Personal training",
    ],
  },
  {
    plan: "Standard",
    ruppes: "$149/",
    time: "Monthly",
    features: [
      "Weekly online meeting",
      "Unlimited learning access",
      "24/7 technical support",
      "Personal training",
      "Business analysis",
    ],
  },
  {
    plan: "Premium",
    ruppes: "$199/",
    time: "Monthly",
    features: [
      "Weekly online meeting",
      "Unlimited learning access",
      "24/7 technical support",
      "Personal training",
    ],
  },
];

export const productData = [
  {
    icon: "productImg1",
    name: " High Alumina Refractory",
    title: "Best High Alumina Refractory Bricks Manufacturers in India",
    discriptionpage: [
      "High Alumina Refractory Bricks are special bricks with more than 50% alumina content. They’re made from baked bauxite or materials rich in alumina. What makes them stand out is their ability to handle really high temperatures, from 1200°C to 1700°C. That’s much hotter than regular fire bricks, making them perfect for tough spots in boilers, kilns, and furnaces.",
      "We’re proud to be one of the top makers of these bricks in India. Whether it’s high alumina fire bricks, refractory bricks, boiler bricks, blocks, or furnace bricks, we’ve got it covered. Our focus is on providing reliable and long-lasting solutions for demanding situations.",
    ],
    poins: [
      {
        title: "Your Source for High Alumina Refractory Bricks in India",
        discription: [
          "The manufacturing process of high alumina refractory bricks involves several critical steps to create a material capable of withstanding extreme temperatures and harsh environments. The process begins with the selection of high-purity raw materials, primarily consisting of alumina-rich sources. These sources could include bauxite, corundum, or synthetic alumina powders, chosen for their high alumina content and minimal impurities.",
        ],
      },
      {
        title: "1. Raw material selection",
        discription: [
          " The first step is to select the raw materials. The most common raw materials used for high alumina refractory bricks are bauxite, diaspore, and kyanite. These materials are all high in alumina content, which is essential for the high refractoriness of the finished bricks.",
        ],
      },
      {
        title: "2. Calcination",
        discription: [
          "The raw materials are then calcined, which is a process of heating them to a high temperature in order to drive off moisture and volatile impurities. This step is important for improving the strength and durability of the finished bricks.",
        ],
      },
      {
        title: "3. Mixing",
        discription: [
          "The calcined raw materials are then mixed with other ingredients, such as binders, fluxes, and additives. The binders help to hold the bricks together, while the fluxes help to lower the melting point of the bricks. The additives can be used to improve the properties of the bricks, such as their refractoriness, thermal shock resistance, or chemical resistance.",
        ],
      },
      {
        title: "4. Forming",
        discription: [
          "The mixed ingredients are then formed into the desired shape of the bricks. This can be done by a variety of methods, such as dry pressing, wet pressing, or isostatic pressing.",
        ],
      },
      {
        title: "5. Drying",
        discription: [
          " The formed bricks are then dried to remove any remaining moisture. This step is important for preventing the bricks from cracking during firing.",
        ],
      },
      {
        title: "6. Firing:",
        discription: [
          "The dried bricks are then fired at a high temperature in a kiln. This step is the most important step in the manufacturing process, as it is during this step that the bricks develop their high refractoriness and other properties.",
        ],
      },
      {
        title: "7. Cooling",
        discription: [
          "The fired bricks are then cooled slowly to prevent them from cracking.",
        ],
      },
      {
        title: "Discrpition:",
        discription: [
          "the manufacturing process of high alumina refractory bricks involves selecting pure raw materials, blending them to achieve desired properties, shaping the mixture into bricks, and subjecting them to high-temperature firing. This intricate process results in the creation of durable, heat-resistant bricks essential for applications in industries where extreme temperatures and harsh conditions are prevalent.",
        ],
      },
      {
        title: "Advantages of High Alumina Bricks",
        discription: [
          "High Heat Resistance",
          "Thermal Insulation",
          "Chemical Resistance",
          "Improved performance",
          "Extended lifespan",
        ],
      },
      {
        title: "Where you can use Alumina Refractory Bricks",
        discription: [
          "Food Industries",
          "Steel Industries",
          "Glass Industries",
          "Furnaces",
          "Machine manufacturing units",
        ],
      },
    ],
  },
  {
    icon: "productImg2",
    name: "SK Series",
    title: "SK Series Bricks Manufacturers and Suppliers in India",
    discriptionpage: [
      "Discover the quality of SK Series Bricks with us. We don’t just make SK bricks; we also provide various reliable refractory materials. Trust us as your go-to supplier for SK Series Bricks.",
      "The “SK Series” typically represents a product line or series of items produced by a company. These series can encompass a wide range of products, such as electronics, appliances, machinery, or even vehicles, depending on the manufacturer.",
      "Each product in the SK Series is often designed with specific features, specifications, and applications in mind, catering to the needs and preferences of consumers and businesses alike. Manufacturers use the “SK Series” naming convention to differentiate their products within a particular family or category.",
    ],
    poins: [
      {
        title: "High Alumina Bricks Supplier and Importers in Kenya",
        discription: [
          "Introduction: High alumina bricks are increasingly used in various industries because they possess characteristics such as high utility value, heat stability, and hardness. In the Kenya market these bricks have been realizing growing demand due to the rise in manufacture of steel, cement and glass industries. Jindal refractories are known suppliers and importers of various kinds of bricks such as sk34, sk36, sk38, sk40 in Kenya, Tanzania & Oman.",
        ],
      },
      {
        title: "What are High Alumina Bricks?",
        discription: [
          "High alumina bricks are simply classified under refractory materials that is the product that has high percentage of alumina being alumina trihydrate (Al₂O₃). They can be developed from alumina-silica, hence they possess high thermal stability, mechanical strength, as well as chemical and physical wear resistance. These properties make the high alumina bricks to be important in the high temperature usage like furnaces, kilns and incinerators. At Jindal you can find all different types of bricks as the leading Exporter and Importer of high alumina bricks like sk34, sk36, sk38, sk40 in Kenya, Tanzania & Oman.",
        ],
      },
      {
        title: "Different Types of High Alumina Bricks",
        discription: [
          "High alumina bricks come in various grades, each designed to meet specific thermal and mechanical requirements. The primary types include",
          "SK 32",
          "SK 34",
          "SK 36",
          "SK 38",
          "SK 40",
          "As Jindal refractories is the leading sk34 supplier and Importers in Kenya, Tanzania & Oman, sk36 supplier and Importers in Kenya, Tanzania & Oman , sk38 supplier and Importers in Kenya, Tanzania & Oman and sk40 supplier and Importers in Kenya, Tanzania & Oman.",
        ],
      },
      {
        title: "SK 32 Bricks",
        discription: [
          "Therefore it can be concluded that SK 32 bricks contain a decent amount of alumina and are good for low to moderate temperature usage. These types of bricks are utilized in areas where low to moderate levels of heat are encountered.",
        ],
      },
      {
        title: "SK 34 Bricks",
        discription: [
          "Higher Thermal Stability and Alumina Content. SK 34 bricks have higher alumina content compared to SK 32; therefore, their thermal stability is better. They are suitable for use in applications which call for a higher temperature such as construction of cement and lime kilns.",
        ],
      },
      {
        title: "SK 36 Bricks",
        discription: [
          " Higher Alumina Content than that of sk32 and sk34 results in Improved Performance. Alumina content in SK 36 bricks is even higher and such bricks withstand high temperatures exceptionally well. These bricks are widely used in steel making industries, non ferrous metals and other high temperature applications.",
        ],
      },
      {
        title: "SK 38 Bricks",
        discription: [
          "High Alumina Content for Extreme Conditions. SK 38 bricks are suitable for high temperature applications and can endure thermal shock and chemical corrosion. They are applied in various industries such as blast furnaces, hot blast stoves, and many others. This product is suitable for severe environments typical of blast furnaces and hot blast stoves.",
        ],
      },
      {
        title: "SK 40 Bricks ",
        discription: [
          "Among all the types of SK 40 bricks, the ones with the highest alumina content are ideal for use in the harshest conditions and high temperatures. These bricks are widely used in such industries as the petrochemical industry due to their high thermal and chemical resistance.",
        ],
      },
      {
        title:
          "Partnering with Jindal: Leading High Alumina BSupplier, Exporter and Importer in Kenya",
        discription: [
          "Jindal Refractories is a leading supplier and importer of high alumina bricks From India. If you are searching for SK 32 Supplier and Importer from India, SK 34 Supplier and Importers from Gujarat, India same goes for SK36, SK38 and SK40 bricks leading supplier and Importers From india. Partnering with Jindal Refractories provides benefits like Quality Assurance, Reliable Supply Chain, and Expertise and Support. Different grades of high alumina bricks are available in Kenya to suit various industrial needs. Also if you are looking to Import these bricks from Kenya , we are also exporting all types of bricks from India like (SK34, SK36, SK38, SK40).",
        ],
      },
      {
        title: "Excellence in SK Series Bricks Production in India",
        discription: [
          "Design and Planning: Before manufacturing can begin, product designers and engineers create detailed plans and specifications for the product. This includes selecting materials, creating blueprints, and designing any necessary tools or molds.",
          "Material Sourcing: Raw materials or components are sourced from suppliers. This can involve procurement of metals, plastics, electronics, chemicals, or other materials required for the product.",
          "Preparation: Raw materials may need to undergo various processes such as cutting, shaping, or treatment to meet the design specifications. This can include processes like casting, forging, or extrusion.",
          "Assembly: Components are assembled according to the product design. This may involve manual labor or automated assembly lines, depending on the complexity and volume of production.",
          "Assembly: Components are assembled according to the product design. This may involve manual labor or automated assembly lines, depending on the complexity and volume of production.",
        ],
      },
      {
        title: "Advantages of SK Series Bricks",
        discription: [
          "10-year warranty",
          "Innovative bonnet pressure release system",
          "Less adjustment and maintenance",
          "Weld-in-place",
          "Patented ergonomic handwheel",
        ],
      },
    ],
  },
  {
    icon: "productImg3",
    name: "Insulation Bricks",
    title: "Insulation Bricks Manufacturer in India",
    discriptionpage: [
      "Choose the best Insulation Bricks from us. We specialize in lightweight and effective refractory insulation materials. Count on us as your trusted supplier for Insulation Bricks.",
      "Insulation bricks, also known as refractory insulation bricks or lightweight refractory bricks, are specialized building materials designed to provide effective thermal insulation in high-temperature environments.These bricks are used to line furnaces, kilns, ovens, and other industrial equipment where reducing heat transfer is crucial.",
      "Insulation bricks are a type of refractory material that is used to insulate high-temperature equipment. They are made from a mixture of refractory aggregates, binders, and water. Once they set, insulation bricks form a strong, durable lining that can withstand high temperatures, abrasion, and chemical attack.",
      "Jindal Refractories is the best Insulation Brick Manufacturer in Bhilwara, Rajasthan, Gujarat and in all over the India.",
    ],
    poins: [
      {
        title: "Best Insulation Bricks Supplier in Indi",
        discription: [
          "The manufacturing process of insulation bricks involves several steps to create lightweight and thermally efficient refractory products. Here’s a general overview of the typical process:",
          "Refractory brick raw materials: First of all, according to the customer and the technical department’s needs，we purchase the raw materials used in the refractory bricks. After entering the factory, check whether the raw materials meet the required standards.",
          "Secondly, some of the qualified raw materials are subjected to secondary treatment as needed to prepare the raw materials.",
          "Ingredients: The qualified aggregates, powders, additives and binders are weighed according to the proportion of the formula.",
          "Mixing: According to the process requirements, the prepared raw materials are kneaded and mixed evenly.",
        ],
      },
      {
        title: "Advantages of Insulation Bricks",
        discription: [
          "Reduced Heat Transfer",
          "Energy Efficiency",
          "Temperature Stability",
          "Lightweight Design",
          "Improved Process Efficiency",
        ],
      },
      {
        title: "Where you can use our Insulation Bricks?",
        discription: [
          "Industrial Furnaces",
          "Kilns and Ovens",
          "Boilers and Heaters",
          "Petrochemical and Chemical Industries",
          "Building and Construction",
        ],
      },
    ],
  },
  {
    icon: "productImg4",
    name: "JM Series",
    title: "JM Series Bricks Manufacturers and Suppliers in India",
    discriptionpage: [
      "In the world of electronics, manufacturers often release product lines or series with similar names or model numbers. The “JM Series” could potentially refer to a line of electronic devices such as smartphones, laptops, or cameras. Each device in the series may have its own unique features and specifications while sharing a common branding or design theme.",
      "In literature, authors sometimes create a series of books that follow a particular theme, storyline, or set of characters. The “JM Series” could represent a collection of books by an author or in a specific genre.",
    ],
    poins: [
      {
        title: "What Are Insulation Bricks?",
        discription: [
          "Thermal insulation is one of the most critical parameters in high-temperature industrial applications to increase energy and plant efficiency. These  bricks have gained popularity in this regard because they are not only heat resistant but also light. These advanced refractory materials have been widely used in areas such as metallurgy, ceramics and glass industries because they help in reducing heat loss and aid in maintaining high temperatures. Consequently, businesses can dramatically reduce the energy use, expenses, and lifespan of their high-temperature equipment by taking advantage of JM Types insulation bricks. Jindal Refractors are leading Supplier, exporter and Importers of JM series bricks, such as JM23, Jm26, Jm28, JM30 in Kenya, Tanzania & Oman.",
        ],
      },
      {
        title: "1. JM 23 Insulation Bricks",
        discription: [
          "Maximum Service Temperature: 1260°C (2300°F)   These bricks are made up with high-purity alumina, with an Al2O3 content of over 99%, which gives exceptional resistance to thermal and chemical industries. The remarkable ability of JM 23 insulation bricks to high temperatures makes them perfect for various industrial uses.",
        ],
      },
      {
        title: "2. JM 26 Insulation Bricks",
        discription: [
          "Maximum Service Temperature: 1430°C (2600°F)  JM 26 alumina refractory bricks are high-temperature made for industrial environments like steelmaking, glass manufacturing, and petrochemical processing. Main ingredient aluminium oxide (Al2O3). These bricks also use extreme heat in furnaces, kilns, and other high-temperature equipment.",
        ],
      },
      {
        title: "3. JM 28 Insulation Bricks",
        discription: [
          "Maximum Service Temperature: 1540°C (2800°F)  JM 28 Alumina Refractory Bricks are highly durable and heat-resistant materials used in various industries. These bricks utilize  extreme temperatures and have excellent thermal properties. With low thermal conductivity, It gives effective insulation against heat transfer. As a result, they are highly sought after for lining furnaces, kilns, and other high-temperature equipment.",
        ],
      },
      {
        title: "4. JM 30 Insulation Bricks",
        discription: [
          "Maximum Service Temperature: 1650°C (3000°F)  JM 30 Alumina Refractory Bricks are considered to be one of the most superior products in the refractory material industry. These are mainly prepared from pure alumina (Al2O3) and these ceramics are very much resistant to high temperature, corrosive agents, and mechanical forces.It has a 30% Alumina content which gives them their excellent thermal stability and makes them ideal for use in the construction of industrial furnaces and kilns, and any other high temperature application.",
        ],
      },
      {
        title: "Industries Benefiting from Insulation Bricks in Kenya",
        discription: [
          "1.Steel Industry: Insulation bricks line the walls of steel furnaces, preventing heat loss and ensuring efficient steel production.",
          "2.Ceramics and Glass Industry: Kilns and glass melting furnaces rely on insulation bricks to maintain consistent temperatures and reduce energy consumption.",
          "3.Power Plants: Insulation bricks insulate boilers, turbines, and chimneys, enhancing overall efficiency.",
          "4.Cement Plants: Kilns and preheaters use insulation bricks to withstand high temperatures during cement production.",
          "As we are one of major JM 23 Suppliers in Kenya, JM 26 Exporter in Kenya same goes for all Jm series bricks 23 to 30 we are the most trusted Supplier, Exporter and Importers of JM bricks in Kenya.",
        ],
      },
      {
        title: "Partnering with Jindal Refractories",
        discription: [
          "Jindal Refractories, the leading manufacturer of insulation bricks provides quality products to the world market. Our JM series bricks provide perfect insulation for any industries that excel thermal energy. Kenyan businesses should enlist the support of Jindal Refractories in order to obtain quality insulating materials accompanied by technical assistance. If you finding for JM23, JM26, JM28, JM30 bricks in Kenya, Tanzania & Oman then Jindal Refractories is a one-stop solution for this needs.",
          "In conclusion, insulation bricks play an important role in energy saving, safety and increasing production rate. For JM 23, JM 26, or JM 28 bricks, it is recommended that you should choose according to your industry need. Keep in mind that choosing the right insulation goes a long way in ensuring the optimal conditions of operation in the various industries across Kenya, Tanzania & Oman.",
          "What you need to know is that, right bricks when it comes to insulation can mean continued operations even under extreme heat. Make the right decisions today and let Jindal Refractories your Kenya’s industrial future.",
        ],
      },
      {
        title: "JM Series Bricks Suppliers",
        discription: [
          "Raw material procurement: The raw materials used in the manufacturing of JM Series scales include steel, plastic, and electronic components. These materials are sourced from suppliers who meet Mettler Toledo’s quality standards.",
          "Part manufacturing: The steel parts for the scales are manufactured using a variety of processes, including stamping, bending, and welding. The plastic parts are injection molded. The electronic components are assembled into subassemblies.",
          "Assembly: The individual parts are assembled into a complete scale. This process includes the installation of the load cell, the display, and the control circuitry.",
          "Testing: Each scale is tested to ensure that it meets Mettler Toledo’s quality standards. This testing includes a visual inspection, a functional test, and a calibration test.",
        ],
      },
      {
        title: "Advantages of Product",
        discription: ["Accuracy", "Warranty", "Ease of use", "Durability"],
      },
    ],
  },
  {
    icon: "productImg5",
    name: "Insulation Material",
    title: "Insulation Material Manufacturer in India",
    discriptionpage: [
      "Insulation materials are essential components used to reduce heat transfer and control temperature variations between different environments. These materials play a crucial role in enhancing energy efficiency, maintaining comfortable living and working conditions, and ensuring the proper functioning of various industrial processes.",
      "Insulation materials are vital components used to manage heat transfer and maintain optimal temperature conditions in various settings. These materials come in different types, each with its own set of thermal properties and applications.",
      "Jindal Refractories is the best Insulation Material Manufacturer in Gujarat, Bhilwara, and in all over the India.",
    ],
    poins: [
      {
        title: "Insulation Material Suppliers in India",
        discription: [
          "The manufacturing process of insulation materials can vary significantly depending on the type of material being produced. Below is a generalized overview of the manufacturing process for a common type of insulation material: fiberglass insulation.",
          "Raw material preparation: The first step in the manufacturing process is to prepare the raw materials. This may involve crushing, screening, and blending the raw materials to ensure that they are the correct size and consistency.",
          "Mixing: The next step is to mix the raw materials together. This is done in a large mixer, where the raw materials are combined with a binder and water. The mixing process is important to ensure that the raw materials are evenly distributed and that the binder is properly dispersed",
          "Forming: Once the mixture is complete, it is formed into the desired shape. This can be done by pouring the mixture into a mold or by extruding it through a die.",
          "Curing: The formed insulation material is then cured, which is a process of allowing it to harden. Curing typically takes several hours or days, depending on the type of insulation material and the curing conditions.",
          "Inspection: Once the insulation material is cured, it is inspected to ensure that it meets the specified quality standards. Inspection typically involves checking the dimensions, density, and strength of the insulation material.",
          "Packaging and shipping: The inspected insulation material is then packaged and shipped to the customer. Packaging typically involves wrapping the insulation material in a protective material and placing it in a shipping container.",
          "The specific manufacturing process of insulation material may vary depending on the type of insulation material and the manufacturer.",
        ],
      },
      {
        title: "Advantages of Insulation Bricks",
        discription: [
          "Energy efficiency",
          "Comfort",
          "Soundproofing",
          "Fire protection",
          "Moisture resistance",
        ],
      },
      {
        title: "Where you can use our Insulation Bricks?",
        discription: [
          "Homes and buildings",
          "Electrical equipment",
          "Industrial applications",
          "Transportation",
          "Agriculture",
        ],
      },
    ],
  },
  {
    icon: "productImg6",
    name: "Monolithic Castable",
    title: "Monolithic Castable Manufacturers in India",
    discriptionpage: [
      "Monolithic castables are a type of refractory material widely used in various industries for lining and repairing high-temperature equipment such as furnaces, kilns, and reactors. Jindal Refractories is the best Monolithic Castable Manufracturers in India. Unlike traditional refractory bricks, which are pre-shaped and then assembled, monolithic castables are mixed, cast, and formed on-site in a malleable state.",
      "Monolithic castables are composed of carefully selected refractory aggregates, binders, and additives. These components are combined with water to form a slurry or a dense mass, which can be poured, gunned, or troweled into place, adapting to the specific requirements of the equipment being lined.",
      "We are the best Monolithic Castable Manufacturer in Gujarat, Wankaner, Katni, Dhanbad, and in all over the India.",
    ],
    poins: [
      {
        title: "Monolithic Castable Suppliers in India",
        discription: [
          "The manufacturing process of monolithic castable products involves several key steps to create a versatile and durable refractory material suitable for various high-temperature applications. Here’s an overview of the typical process:",
          "Raw material preparation : The raw materials for monolithic castable are first prepared by crushing, screening, and blending. The aggregates are typically crushed to a size of 20-40 mesh, while the binders are ground to a finer particle size.",
          "Mixing : The prepared raw materials are then mixed together in a mixer. The mixing process is important to ensure that the raw materials are evenly distributed and that the binder is properly dispersed.",
          "Pugging : The mixed material is then pugged, which is a process of compacting the material under pressure. Pugging helps to remove air bubbles from the material and to create a uniform consistency.",
          "Dewatering : The pugged material is then dewatered to remove excess water. Dewatering is important to ensure that the castable has the correct consistency for pouring or spraying.",
        ],
      },
      {
        title: "Advantages of Insulation Bricks",
        discription: [
          "Flexibility in Installation",
          "Seamless Linings",
          "Reduced Heat Loss",
          "Thermal Insulation",
          "Uniform Properties",
        ],
      },
      {
        title: "Where you can use Monolithic Castable?",
        discription: [
          "Furnaces",
          "Kilns",
          "Metallurgical applications",
          "Chemical processing",
          "monolithic castable products offer advantages such as installation flexibility, seamless linings, reduced heat loss, and improved thermal insulation.",
        ],
      },
    ],
  },
  {
    icon: "productImg7",
    name: "MORTAR",
    title: "Refractory Mortar Cement Manufacturers in India",
    discriptionpage: [
      "Welcome to Jindal Refractories, a the best Refractory Mortar manufacturer in India. Our professional Refractory Mortar is meticulously designed for top-temperatures heat retaining material requires. Proudly presenting ourselves as business executives, we provide mortar that significantly lowers overall coating occurrence and chilly deal with conditions.",
      "Perfect for varied programs, our Refractory Mortar realizes its devote commercial market sectors like Fire Clay, Accoset 50, 60% Mortar, 70% Mortar and Thermotex Paste Mortar. Investigate its adaptability in sub-ground applications, such as Chrome Magnesite Bricks and Sillimanite Bricks. Like a respected Refractory Mortar Maker in Gujarat, Wankaner, Katni, and Dhanbad, Jindal Refractories assures top-notch options to your substantial-temp heat retaining material needs.",
      "Mortar is made by mixing cement, sand, and water together in the correct proportions. The type of cement used will depend on the application. For example, Portland cement is typically used for general-purpose mortar, while high-strength cement is used for mortar that needs to be more durable. The type of sand used will also depend on the application.",
      "For example, mason sand is typically used for general-purpose mortar, while river sand is used for mortar that needs to be more fine-grained. The amount of water used will depend on the desired consistency of the mortar. Too much water will make the mortar too runny, while too little water will make the mortar too dry and difficult to work with.",
      "Jindal Refractories is the best Refractory Mortar Cement Manufacturer in Gujarat, Wankaner, Katni, Dhanbad, and in all over the India.",
    ],
    poins: [
      {
        title: "Best Refractory Mortar Cement Suppliers in India",
        discription: [
          "Gathering Raw Materials: The primary raw materials for mortar are cementitious materials (such as Portland cement), sand, and water. Depending on the specific requirements, additional additives like lime, admixtures, or pigments may also be used.",
          "Measuring Proportions: The proportions of cementitious materials and aggregates are carefully measured based on the type of mortar being produced and the application’s requirements. The specific ratios determine factors like strength, workability, and setting time.",
          "Mixing: The dry ingredients, including cement and sand, are thoroughly mixed to ensure even distribution. This can be done using mechanical mixers or manual methods, depending on the scale of production.",
          "Adding Water: Water is added gradually to the dry mixture while mixing continues. The amount of water added influences the consistency and workability of the mortar. The goal is to achieve a homogeneous mixture with the desired texture.",
          "Mixing Continuation: The mixing process continues until the mortar reaches a consistent and uniform texture. This ensures that the cement particles are well dispersed and properly hydrated.",
        ],
      },
      {
        title: "Advantages of Refractory Mortar Cement",
        discription: [
          "Bonding Strength",
          "Load Distribution",
          "Weather Resistance",
          "Fire Resistance",
          "Aesthetic Appeal",
        ],
      },
      {
        title: "Where You Can Use Mortar Cement",
        discription: [
          "Bricklaying",
          "Stone Masonry",
          "Block Masonry",
          "Fireplace Construction",
          "Decorative Masonry",
        ],
      },
    ],
  },
  {
    icon: "productImg8",
    name: "Acid Proof Bricks",
    title: "Acid Proof Bricks Manufacturers in India",
    discriptionpage: [
      "Acid-proof lining materials are specialized products used to protect surfaces from the corrosive effects of acids and other aggressive chemicals. These linings are applied to various industrial equipment and structures to extend their service life and maintain their integrity in environments where exposure to corrosive substances is prevalent.",
      "Acid-proof linings find application in industries such as chemical processing, petrochemicals, mining, metal finishing, wastewater treatment, and more. There are different types of acid-proof lining materials, each with specific properties and applications.",
      "Jindal Refractories is the best Acid Proof Bricks Manufacturer in Gujarat, Madhya Pradesh, and in all over the India.",
    ],
    poins: [
      {
        title:
          "Acid Proof Bricks Suppliers, Exporters and Importers in India as well as Kenya, Oman and Tanzania",
        discription: [
          "Jindal Refractories is the best Acid Proof Bricks Manufacturer in Gujarat, Madhya Pradesh, and in all over India. Other than that we are also Acid Proof Bricks Exporter and Importer in Kenya as well as  Acid Proof Bricks Supplier in Kenya, Oman and Tanzania. Our years of experience in this manufacturing industry set us apart from other suppliers and manufacturers.",
          "The manufacture process of acid-proof bricks involves carefully selecting and processing raw materials to create bricks that are highly resistant to corrosion from acids and other aggressive chemicals. Acid-proof bricks are often used in industries such as chemical processing, metal pickling, and more.",
          "The process begins with the careful selection of raw materials. Resistant aggregates such as silica, alumina, and other non-metallic materials with high chemical resistance are chosen. These aggregates will form the backbone of the acid-proof bricks, providing the necessary resilience against corrosive substances. Binders, which hold the aggregates together, are also selected. High alumina cements, sodium silicate, and occasionally epoxy resins are common choices for binders due to their chemical stability.",
          "Once the raw materials are chosen, they are mixed in precise proportions to achieve the desired properties of the acid-proof bricks. Additives may be introduced to improve workability and other characteristics. The mixture is then shaped into brick forms using molds or through pressing and extrusion methods. This shaping process ensures uniformity and consistency in the structure of the bricks.",
          "The shaped bricks are allowed to air dry to remove excess moisture and prevent cracking during firing. Subsequently, the dried bricks undergo firing in kilns at high temperatures. This firing process is crucial as it causes the aggregates and binders to chemically react and fuse together, resulting in a dense and durable brick structure. Controlled heating and cooling cycles during firing contribute to the bricks’ chemical stability and mechanical strength.",
        ],
      },
      {
        title: "Advantages of Acid Proof Bricks",
        discription: [
          "Chemical Resistance",
          "Longevity and Durability",
          "Protection of Infrastructure",
          "Reduced Maintenance Costs",
          "Thermal Insulation",
        ],
      },
      {
        title: "Where You Can Use Acid Proof Bricks",
        discription: [
          "Acid proof bricks are required in numerous sectors of industries because of their reliability as well as fireproof and heat resisting character. They are used for tank lining, floor and drain lining in chemical plants and power stations where acids and chemicals are used. In pharmaceutical and food processing industries, these bricks provide both sanitary and chemical immunization. These are used in water treatment plants to guard structures against destructive purification agents. Laboratories as their surfaces for the benches and floors are strong require less reinforcement. On balance, acid proof bricks improve safety, durability, and productivity because of the corrosiveness of the surrounding environment.",
          "Chemical Processing Plants",
          "Electroplating Facilities",
          "Battery Manufacturing",
          "Steel and Metal Processing",
          "Laboratories",
          "It’s important to select the right type of acid-proof brick material and installation method based on the specific chemicals, temperatures, and conditions in the application. Proper installation and maintenance are crucial for the long-term effectiveness of acid-resistant brick linings.",
        ],
      },
    ],
  },
  {
    icon: "productImg9",
    name: "Magnesite Bricks",
    title: "Refractory Magnesite Bricks Manufacturers",
    discriptionpage: [
      "Magnesite bricks are available in a variety of shapes and sizes to meet the specific requirements of different applications. They are typically made from high-purity magnesium oxide, but they may also contain other materials, such as dolomite or chrome oxide, to improve their properties.",
      "Magnesite bricks are a versatile and reliable choice for a variety of high-temperature applications. They offer excellent refractoriness, slag resistance, thermal conductivity, and durability. However, they are also more expensive and heavier than some other types of refractory bricks.",
    ],
    poins: [
      {
        title: "Refractory Magnesite Bricks Suppliers",
        discription: [
          "Burned magnesite bricks",
          "Fused magnesite bricks",
          "Magnesite alumina bricks",
          "Magnesite chrome bricks",
          "Refractory properties: Magnesite bricks have excellent refractory properties, meaning they can withstand high temperatures without melting or disintegrating. They have a melting point of 2,800 degrees Fahrenheit and can withstand temperatures up to 3,000 degrees Fahrenheit for short periods of time. Magnesite bricks are also resistant to thermal shock, which is the rapid change in temperature that can cause other types of refractory bricks to crack or shatter.",
          "Slag resistance: Magnesite bricks are also resistant to slag attack, which is the erosion of refractory materials by molten slags. Slags are byproducts of metal refining and can be very corrosive. Magnesite bricks are able to resist slag attack because they have a high melting point and are chemically inert.",
          "Thermal conductivity: Magnesite bricks have good thermal conductivity, which means they can transfer heat well. This is important in applications where it is necessary to evenly distribute heat, such as in furnaces.",
          "Durability: Magnesite bricks are durable and can withstand the harsh conditions of high-temperature applications. They are resistant to erosion, spalling, and cracking.",
        ],
      },
      {
        title:
          "Magnesite bricks are used in a variety of high-temperature applications, including:",
        discription: [
          "Basic open-hearth furnaces",
          "Electric furnaces",
          "Converter furnaces",
          "Non-ferrous metal melting furnaces",
          "High temperature tunnel kilns",
          "Cement rotary kiln",
          "Magnesite bricks are a versatile and reliable choice for a variety of high-temperature applications. They offer excellent refractoriness, slag resistance, thermal conductivity, and durability. However, they are also more expensive and heavier than some other types of refractory bricks.",
        ],
      },
      {
        title: "Advantages of Our Product",
        discription: [
          "High Refractoriness",
          "Comfort",
          "Good slag resistance",
          "Fire protection",
          "High thermal conductivity",
        ],
      },
      {
        title: "Where you can use Magnesite Bricks?",
        discription: [
          "Furnaces",
          "Kilns",
          "Metallurgical applications",
          "Chemical processing",
        ],
      },
    ],
  },
];

export const chooseData = [
  {
    icon: "ProductItemone",
    title: "Seamless Integration",
    description:
      "Experience the ease of integrating JustPayze into your platform. Our seamless integration process ensures that you can start accepting payments without any hassle. Say goodbye to complex setups and hello to a smoother payment experience for your customers.",
  },
  {
    icon: "ProductItemtwo",
    title: "Dashboard with Real-Time Insights",
    description:
      "Monitor your transactions and gain valuable insights with our feature-rich dashboard. Access real-time big data outputs that you the information needed to make informed decisions. Stay ahead of the curve and optimize your payment processes effortlessly.",
  },
  {
    icon: "ProductItemthree",
    title: "User-Friendly Checkout Pages",
    description:
      "Enhance your customers' experience with our user-friendly checkout pages. We understand the importance of a smooth and intuitive payment process. With JustPayze, you can create a seamless checkout journey that fosters customer satisfaction and loyalty.",
  },
  {
    icon: "ProductItemfour",
    title: "Highest Security Measures at Every Step",
    description:
      "Security is our top priority. Rest easy knowing that JustPayze employs the highest security measures at every step of the payment process. From data encryption to fraud prevention,we've got you covered, ensuring a secure environment for both you and your customers.",
  },
];

export const accordionFullData = [
  {
    id: "flush-headingOne",
    title: "What types of concrete blocks do you offer?",
    content:
      "We provide a variety of concrete blocks, including standard blocks, insulated blocks, decorative blocks, and specialty blocks tailored for specific applications.",
  },
  {
    id: "flush-headingTwo",
    title:
      "What are the advantages of using concrete blocks over other building materials?",
    content:
      "Concrete blocks offer superior strength, durability, fire resistance, and energy efficiency. They also require less maintenance and provide excellent sound insulation.",
  },
  {
    id: "flush-headingThree",
    title: "Are your concrete blocks environmentally friendly?",
    content: `Yes, our blocks are produced using sustainable materials and processes, minimizing the environmental impact while maintaining high-quality standards.`,
  },
  {
    id: "flush-headingFour",
    title: "How do I determine the right block size for my project?",
    content: `The appropriate block size depends on your specific project requirements, including structural needs and design preferences. Our team can help you select the best options based on your plans.`,
  },
  {
    id: "flush-headingFive",
    title: "Can your concrete blocks be customized?",
    content: `Absolutely! We offer customization in terms of size, shape, texture, and color to meet your unique project specifications.`,
  },
  {
    id: "flush-headingSix",
    title: "What is the typical lifespan of your concrete blocks?",
    content: `Our concrete blocks are designed for longevity, typically lasting 50 years or more with proper maintenance and care.`,
  },
];

export const collaborators = [
  { number: "350", symbol: "+", text: "Merchants Trust Us" },
  { number: "65", symbol: "+", text: "Businesses Partner with Us" },
];

export const whyChoosedata = [
  {
    icon: "whyChoose1",
    title: "Quality Assurance",
    description: `We adhere to strict quality control processes, ensuring that every block meets industry standards for strength, durability, and performance, giving you confidence in your construction.`,
  },
  {
    icon: "whyChoose2",
    title: "Innovative Solutions",
    description: `Our commitment to research and development allows us to offer cutting-edge concrete products designed to meet modern building needs, including energy efficiency and sustainability.`,
  },
  {
    icon: "whyChoose3",
    title: "Experienced Team",
    description: `With years of industry experience, our knowledgeable team provides expert guidance and support throughout the entire process, from selection to delivery and installation`,
  },
  {
    icon: "whyChoose4",
    title: "Customization Options",
    description: `We understand that every project is unique, which is why we offer a wide range of customizable concrete blocks in various sizes, shapes, and finishes to fit your specific requirements.`,
  },
];

export const backboneData = [
  {
    icon: "whyChoose1",
    title: "Adopting Up-to-date Technology",
    description: `Our R&D team has adopted the most up-to-date technologies to improve the design & quality standard of our products.`,
  },
  {
    icon: "whyChoose2",
    title: "Trailblazing Manufacturing Methods",
    description: `As a leading refractory manufacturer, we are supported by an experienced team of professionals to maintain the trailblazing manufacturing methods.`,
  },
  {
    icon: "whyChoose3",
    title: "Your Reliable Partner",
    description: `In a continuously changing world, we are your reliable partner by providing QA-approved refractory materials to make industrial processes more efficient.`,
  },
];

export const aboutCount = [
  { number: "350", symbol: "+", text: "Merchant Join" },
  { number: "7", symbol: "+", text: "Year Experience" },
  { number: "1800", symbol: "+", text: "Success Transaction" },
  { number: "65", symbol: "+", text: "Businesses Partner with Us" },
];

export const aboutPoints = [
  {
    phase: "60",
    text: "Industrial Solutions",
  },
  {
    phase: "5",
    text: "Years Experience",
  },
  {
    phase: "150",
    text: "Projects Completed",
  },
];

export const aboutPagePoints = [
  {
    icon: "aboutCount1",
    phase: "Who We Are?",
    text: "We are a trusted manufacturer of concrete blocks, dedicated to delivering high-quality products that meet the diverse needs of the construction industry. Our commitment to excellence and innovation drives us to produce durable and reliable building materials, ensuring our clients can construct safe and lasting structures.",
  },
  {
    icon: "aboutCount2",
    phase: "Our vision",
    text: "To become a global leader in the concrete block industry, known for our unwavering commitment to quality, sustainability, and innovation. We envision a future where our products contribute to resilient infrastructure and environmentally responsible construction practices worldwide.",
  },
  {
    icon: "aboutCount3",
    phase: "Our Mission",
    text: "Our mission is to provide builders and developers with superior concrete block solutions that combine strength, durability, and eco-friendliness. We strive to enhance our manufacturing processes continually, ensuring that our products not only meet but exceed industry standards while fostering a culture of integrity and collaboration in everything we do.",
  },
];

export const ourClientsData = [
  {
    icon: "clientImg1",
  },
  {
    icon: "clientImg2",
  },
  {
    icon: "clientImg3",
  },
  {
    icon: "clientImg4",
  },
  {
    icon: "clientImg5",
  },
  {
    icon: "clientImg6",
  },
  {
    icon: "clientImg7",
  },
];

export const blogData = [
  {
    icon: "blog1",
    stock: "The Future of Trading",
    title: "Why Secure Payment Gateways Are Essential for Gaming Platforms",
    date: "October 6, 2023",
    name: "James R.",
    discription:
      "As online gaming continues to grow, so do the risks associated with handling financial transactions. From fraud to data breaches, gaming platforms face unique challenges that make secure payment gateways a necessity. Players expect a safe and reliable payment experience, and gaming businesses must deliver it to maintain their reputation and user trust.",
    discriptionpage: [
      "A secure payment gateway uses encryption and tokenization to protect sensitive data such as credit card information, personal details, and account credentials. This ensures that, even if a breach occurs, the data remains unreadable to unauthorized parties. By implementing advanced security measures like SSL encryption, two-factor authentication, and fraud detection tools, gaming platforms can reduce the risk of fraud, chargebacks, and data theft.",
      "Beyond security, reliable payment gateways ensure smooth, real-time transactions, keeping players in the game with minimal disruptions. Players are more likely to trust and return to a platform that prioritizes their security. Therefore, integrating a secure payment gateway is more than just a safety measure—it’s an investment in the long-term success of your gaming platform.",
    ],
    poins: [
      {
        title: "1. Safeguarding Player Data",
        discription: [
          "Online gamers are often required to provide sensitive information like credit card details or bank account numbers. Without proper encryption and security protocols, this data is vulnerable to cyber-attacks. Secure payment gateways use advanced encryption methods and comply with international security standards like PCI-DSS to keep data safe from hackers.",
        ],
      },
      {
        title: "2. Boosting User Confidence",
        discription: [
          "Trust is critical in the gaming industry. If players sense that your platform lacks proper security, they will hesitate to make purchases or deposit funds. By integrating a secure payment gateway, you demonstrate that their safety is a priority, which in turn boosts retention rates and long-term loyalty.",
        ],
      },
      {
        title: "3. Minimizing Chargebacks and Fraud",
        discription: [
          "Gaming platforms are particularly susceptible to fraudulent activities. Secure payment gateways come equipped with fraud detection and prevention tools, which can help reduce chargebacks and financial losses by identifying suspicious transactions in real time.",
        ],
      },
      {
        title: "Conclusion:",
        discription: [
          "A secure payment gateway is essential to protect both your business and your players. As online gaming continues to grow, investing in top-tier security features ensures smooth, secure, and trustworthy transactions.",
        ],
      },
    ],
  },
  {
    icon: "blog2",
    stock: "Algorithmic Trading 101",
    title:
      "How Multi-Currency Support Can Expand Your Gaming Audience Globally",
    date: "October 6, 2023",
    name: "James R.",
    discription:
      "The gaming industry is no longer limited by geographical boundaries. With players from different parts of the world engaging in your platform, it’s crucial to offer flexible payment options, including multi-currency support. Accepting multiple currencies doesn’t just make transactions easier for international players; it opens the door to a larger, global audience",
    discriptionpage: [
      "The gaming industry is no longer limited by geographical boundaries. With players from different parts of the world engaging in your platform, it’s crucial to offer flexible payment options, including multi-currency support. Accepting multiple currencies doesn’t just make transactions easier for international players; it opens the door to a larger, global audience",
      "When players are able to make payments in their own currency, they’re less likely to be deterred by hidden fees or fluctuating exchange rates. Multi-currency support also demonstrates that your platform is accessible and user-friendly for players from various countries, improving their overall experience.",
      "Moreover, offering local currencies can lead to a higher conversion rate and more successful transactions, as players feel more comfortable knowing exactly how much they’re spending. It can also reduce chargebacks due to currency misunderstandings. By integrating a payment gateway that supports multiple currencies, you can increase player retention and create new opportunities for growth in international markets.",
    ],
    poins: [
      {
        title: "1. Breaking Down Currency Barriers",
        discription: [
          "Players are more likely to engage with and trust platforms that allow them to pay in their local currency. Multi-currency support removes the friction of converting currencies, which can be time-consuming and cause hesitation for potential players. This feature makes your platform more user-friendly and helps reduce payment friction.",
        ],
      },
      {
        title: "2. Boosting International Sales",
        discription: [
          "With the rise of global gaming, your platform can attract players from various countries. Offering a seamless payment experience in their preferred currency encourages more players to make purchases, increasing your revenue. Multi-currency gateways simplify cross-border transactions and help avoid currency exchange fees that deter international customers.",
        ],
      },
      {
        title: "3. Enhancing User Experience",
        discription: [
          "Multi-currency support is not just about offering more currencies; it’s about providing a better user experience. Players can make payments faster and with more confidence when they don’t have to worry about exchange rates or hidden fees. This added convenience makes your platform stand out in a competitive market.",
        ],
      },
      {
        title: "Conclusion:",
        discription: [
          "Expanding globally means thinking locally. By offering multi-currency support, you break down financial barriers and provide a more inclusive gaming environment. This simple feature can be the key to growing your audience and boosting your platform's success worldwide. ",
        ],
      },
    ],
  },
  {
    icon: "blog3",
    stock: "Strategies You Should Know",
    title:
      "The Importance of Fraud Prevention Tools for High-Risk Gaming Transactions",
    date: "October 6, 2023",
    name: "James R.",
    discription:
      "Gaming platforms are often categorized as high-risk businesses due to their large volume of financial transactions, the presence of in-game purchases, and the anonymity of many users. This makes them prime targets for fraud, hacking, and other malicious activities. To protect your platform and your players, robust fraud prevention tools are a must.",
    discriptionpage: [
      "Gaming platforms are often categorized as high-risk businesses due to their large volume of financial transactions, the presence of in-game purchases, and the anonymity of many users. This makes them prime targets for fraud, hacking, and other malicious activities. To protect your platform and your players, robust fraud prevention tools are a must.",
      "Fraud detection tools can help you monitor transactions in real-time, flagging suspicious behavior and preventing fraudulent transactions before they’re completed. AI and machine learning algorithms analyze patterns in user behavior, detecting anomalies such as repeated failed login attempts, unusual spending habits, or rapid purchases from multiple IP addresses",
      "These tools also provide chargeback management, reducing the financial loss and administrative burden caused by fraudulent transactions. With fraud prevention measures in place, gaming platforms can safeguard their revenue and protect their players from falling victim to scams. Moreover, building a reputation as a secure platform enhances player trust, increasing long-term engagement.",
    ],
    poins: [
      {
        title: "1. Real-Time Fraud Detection",
        discription: [
          "Fraud in high-risk industries like gaming can happen within seconds. To combat this, payment gateways must offer real-time fraud detection tools. These tools use machine learning algorithms to monitor patterns, flag unusual activity, and halt suspicious transactions before any damage can be done.",
        ],
      },
      {
        title: "2. Reducing Chargebacks",
        discription: [
          "Chargebacks are a significant problem for gaming platforms, especially when dealing with high-risk transactions. Fraud prevention tools can identify suspicious patterns and prevent unauthorized transactions, thereby reducing the likelihood of chargebacks. This saves the platform from losing both revenue and reputation.",
        ],
      },
      {
        title: "3. Protecting User Trust",
        discription: [
          "Trust is vital in the gaming industry. If users believe your platform is vulnerable to fraud, they will hesitate to engage, deposit funds, or make purchases. Fraud prevention tools are essential for maintaining a trustworthy platform that gamers feel safe using.",
        ],
      },
      {
        title: "Conclusion:",
        discription: [
          "Fraud prevention tools are not just a precaution—they’re a necessity for high-risk gaming platforms. Investing in these tools can protect your business from significant financial loss and ensure your users feel safe while enjoying their gaming experience.",
        ],
      },
    ],
  },
];
