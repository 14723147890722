import "./App.css";
import ScrolltoTop from "./ScrolltoTop";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./features/home/Home";
import Footer from "./features/footer/Footer";
import PrivacyPolicy from "./features/privacypolicy/PrivacyPolicy";
import TermsCondition from "./features/termscondition/TermsCondition";
import RefundReturn from "./features/refundreturnpolicy/RefundReturn";
import Header from "./features/header/Header";
import AboutUs from "./features/about/AboutUs";
import ContactUs from "./features/contact/ContactUs";
import Services from "./features/service/Services";
import Product from "./features/product/Product";
import ProductDetail from "./features/product/ProductDetail";
import OrderCancelation from "./features/ordercancelation/OrderCancelation";
import Login from "./features/login/Login";
import ProductInquiry from "./features/product/ProductInquiry";
import InquiryDetail from "./features/product/InquiryDetail";

const routes = [
  { path: "/", element: <Home />, index: true },
  { path: "/home", element: <Home /> },
  { path: "/about-us", element: <AboutUs /> },
  { path: "/feature", element: <Services /> },
  { path: "/product", element: <Product /> },
  { path: "/product-detail", element: <ProductDetail /> },
  { path: "/product-inquiry", element: <ProductInquiry /> },
  { path: "/inquiry-detail", element: <InquiryDetail /> },
  { path: "/contact-us", element: <ContactUs /> },
  { path: "/privacy-policy", element: <PrivacyPolicy /> },
  { path: "/terms-condition", element: <TermsCondition /> },
  { path: "/refund-return", element: <RefundReturn /> },
  { path: "/cancellation-policy", element: <OrderCancelation /> },
  { path: "/login", element: <Login /> },
];

function App() {
  return (
    <>
      <Router>
        <Header />
        <ScrolltoTop />

        <Routes>
          {routes.map((data, index) => (
            <Route
              key={index}
              path={data?.path}
              element={data?.element}
              index={data?.index}
            />
          ))}
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
