import React, { useEffect, useState } from "react";
import { Icon } from "../../ui";
import {
  collaborators,
  pricingData,
  serviceOfferData,
  whyChoosedata,
} from "../home/mock";
import Button from "../../ui/elements/Button";
import { useNavigate } from "react-router-dom";
import { TbCircleDot } from "react-icons/tb";

const Services = () => {
  const navigate = useNavigate();
  const [counts, setCounts] = useState(collaborators.map(() => 0));

  useEffect(() => {
    collaborators.forEach((item, index) => {
      const updateCount = () => {
        let currentCount = 0;
        const increment = Math.ceil(item.number / 200); // Smaller increment for slower counting
        const interval = setInterval(() => {
          currentCount += increment;
          if (currentCount >= item.number) {
            clearInterval(interval);
            setCounts((prevCounts) =>
              prevCounts.map((count, i) => (i === index ? item.number : count))
            );
          } else {
            setCounts((prevCounts) =>
              prevCounts.map((count, i) => (i === index ? currentCount : count))
            );
          }
        }, 80); // Increase interval delay for a slower counting effect
      };
      updateCount();
    });
  }, []);

  return (
    <>
      <div className="commna_herosec position-relative">
        <div className="container">
          <div className="text-white">
            <h1 className="text-[50px] md:text-[40px] sm:text-[32px] tsm:text-[24px] font-bold ">
              Feature
            </h1>
            <p className="tsm:text-sm">Precision in Every Block</p>
          </div>
        </div>
      </div>
      <section className="comman_space bg-primary-200 aibg">
        <div className="container">
          <div className="flex justify-center my-2">
            <div className="w-1/2 sm:w-[75%] tsm:w-full text-center">
              <div className="commantext ">
                <h4>Why Choose Us?</h4>
              </div>
            </div>
          </div>

          <div className="flex gap-8 items-center sm:flex-col pt-8">
            <div className="w-1/2 sm:w-full">
              <div className="grid grid-cols-2 gap-4 tsm:grid-cols-1">
                {whyChoosedata.map((item, index) => (
                  <div key={index} className="pb-4">
                    {/* <Icon name={data?.icon} className="rounded-md" /> */}
                    <div className="flex gap-2 items-center pb-2 text-primary-50">
                      <TbCircleDot />
                      <h5 className="text-2xl md:text-lg tsm:text-lg font-semibold ">
                        {item?.title}
                      </h5>
                    </div>
                    <p className="text-primary-50 text-base sm:text-sm">
                      {item?.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
            <div className="w-1/2 sm:w-full">
              <Icon name="whyChoos" className="img-fluid m-auto rounded-lg" />
            </div>
          </div>

          {/* <div className="grid grid-cols-3 md:grid-cols-2 sm:grid-cols-2 tsm:grid-cols-1 gap-8 pt-16 tsm:p-4">
            {pricingData?.map((item, index) => (
              <div
                className={`plan_main flex flex-col justify-between ${
                  index === 1 ? "plan_main2 !bg-primary-main " : ""
                }`}
                key={index}
              >
                <div
                  className={`${
                    index === 1 ? "text-white" : "text-primary-main"
                  }`}
                >
                  <h5 className="text-xl font-medium">{item?.plan}</h5>
                  <p className="text-[32px] font-bold border-b border-primary-50 pb-2">
                    {item?.ruppes}{" "}
                    <span className="text-[20px] font-normal ">
                      {item?.time}
                    </span>
                  </p>
                  <div className="pt-4">
                    {item?.features.map((feature, index) => (
                      <p key={index} className="flex gap-3 items-center pb-2">
                        <FiCheckSquare />
                        {feature}
                      </p>
                    ))}
                  </div>
                </div>
                <div className="relative overflow-hidden mt-2">
                  <Button
                    class={`w-full py-4 !rounded-full font-semibold ${
                      index === 1
                        ? "bg-white !text-primary-main"
                        : "text-white bg-primary-main"
                    }`}
                  >
                    <span className="flex gap-2 items-center justify-center">
                      Purchase Now <GoArrowUpRight />
                    </span>
                  </Button>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </section>
      <div className="container">
        <section>
          <div className="flex gap-8 items-center sm:flex-col">
            <div className="w-1/2 sm:w-full">
              <Icon
                name="servicePage"
                className="img-fluid m-auto rounded-lg"
              />
            </div>
            <div className="w-1/2 sm:w-full">
              <div className="">
                <span className="border-b border-primary-main">
                  Strengthening Tomorrow
                </span>
                <h4 className="text-3xl sm:text-xl tsm:text-lg font-semibold">
                  Building Resilient Communities with Innovative Concrete
                  Solutions
                </h4>
                <p className="py-4 text-black tsm:text-sm">
                  Our vision is to lead the concrete industry in creating
                  sustainable, innovative building solutions that not only meet
                  the demands of modern construction but also contribute
                  positively to the environment and society. We are committed to
                  producing high-quality concrete blocks that empower builders
                  and architects to design and construct durable,
                  energy-efficient structures.
                </p>
                <p className="py-4 text-black tsm:text-sm">
                  By prioritizing eco-friendly practices and investing in our
                  people and technologies, we aim to build a legacy of quality
                  and integrity that inspires confidence and trust in our
                  partners and customers."
                </p>

                <div className="mt-6">
                  <Button
                    className="primarybtn !py-2"
                    onClick={() => {
                      navigate("/contact-us");
                    }}
                  >
                    Read More
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="trade" className="comman_space">
          <div className="commantext text-center w-[60%] sm:w-[85%] tsm:w-full m-auto">
            <h4>Tailored Payment Solutions for Your Business</h4>
            <p>
              From secure transactions to global payment integration, we provide
              a full suite of services designed to streamline your payment
              processes and drive business growth
            </p>
          </div>
          <div className="grid grid-cols-3 gap-4 md:grid-cols-2 sm:grid-cols-1 py-6 ">
            {serviceOfferData?.map((item, index) => {
              return (
                <div class="feature_boxcard text-center" key={index}>
                  {/* <span className="text-[60px] font-extrabold span_text">{index + 1}</span> */}
                  <div className="bg-primary-100 rounded-full w-fit m-auto p-6 tsm:p-4">
                    <Icon
                      name={item?.icon}
                      className="img-fluid w-[50px] tsm:w-[35px]"
                    />
                  </div>
                  <div className="pt-4">
                    <h5 className="text-primary-50 text-2xl md:text-lg tsm:text-lg font-semibold pb-2">
                      {item?.title}
                    </h5>
                    <p className="text-primary-50 text-base sm:text-sm">
                      {item?.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </section>
      </div>
    </>
  );
};

export default Services;
